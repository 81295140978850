import React from 'react'
import Document from './Document'

function KycDetails() {
  return (
   <>
   <Document/>
   </>
  )
}

export default KycDetails