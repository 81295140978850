import React, { useState } from 'react'
import logo from '../../Assets/Images/IntegratedlogoJpg.jpg'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import apiUrls from '../../Api';

function UpdatePassword() {
    const navigate = useNavigate()
    const apiurl = apiUrls.ForgotPassword
    const apiChangePassword = apiUrls.Verification;
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [enteredOtp, setEnteredOtp] = useState('');
    const [otpVerified, setOtpVerified] = useState(false)
    const [password, setPassword] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault();

        axios
            .post(apiurl, {
                email,
            })
            .then((response) => {
                // console.log(response.data , "Line 27 for otp")
                if (response.data.success === true) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Check your email to verify the OTP',
                        icon: 'success',
                        confirmButtonColor: '#FF9843',
                        confirmButtonText: 'OK',
                    });
                    setOtp(response.data.otp);
                } else {
                    Swal.fire({
                        title: 'Warning',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonColor: '#FF9843',
                        confirmButtonText: 'OK',
                    });
                    setEmail('');
                }
            });
    };
    function handleVerify(e) {
        e.preventDefault();
        if (Number(otp) === Number(enteredOtp)) {
            Swal.fire({
                title: 'Success',
                text: 'Your Otp is correct,U can change your password',
                icon: 'success',
                confirmButtonColor: '#FF9843',
                confirmButtonText: 'OK',
            });
            setOtpVerified(true)
        } else {
            Swal.fire({
                title: 'Warning',
                text: 'Wrong Otp',
                icon: 'warning',
                confirmButtonColor: '#FF9843',
                confirmButtonText: 'OK',
            });
            setEnteredOtp('')
        }
    }
    function handlePasswordUpdate(e) {
        e.preventDefault();
        axios.post(apiChangePassword, {
            email: email,
            password: password
        })
            .then((response) => {
                console.log(response.data)
                if (response.data.success === true) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Password changed successfully',
                        icon: 'success',
                        confirmButtonColor: '#FF9843',
                        confirmButtonText: 'OK',
                    });
                    navigate('/');
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonColor: '#FF9843',
                        confirmButtonText: 'OK',
                    });
                }
            })
    }
    return (
        <>
            <div className='container'>
                <div className='row d-flex justify-content-center '>
                    <div className='image-div d-flex justify-content-center mt-3'>
                        <img src={logo} className='image-login' />
                    </div>
                    <div className='login-main-div mt-4'>
                        <div className='login-title text-center mt-3'>
                            <h4 className='mt-1'>Forgot Password</h4>
                        </div>
                        <Form className='mt-3' >
                            <>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                    <Form.Control
                                        className='input-login'
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                        placeholder='Email' />
                                </Form.Group>
                                {
                                    otp ? (
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control
                                                className='input-login'
                                                type="text"
                                                value={enteredOtp}
                                                onChange={e => setEnteredOtp(e.target.value)}
                                                required
                                                placeholder='Enter the otp' />
                                        </Form.Group>
                                    ) : (
                                        ''
                                    )
                                }
                                {
                                    otpVerified ? (
                                        <>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control
                                                    className='input-login'
                                                    type='password'
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                    placeholder='Enter new password'
                                                />
                                            </Form.Group>
                                        </>
                                    ) : (
                                        ''
                                    )
                                }

                            </>
                            <div className="submit-container-login mt-5">


                                {otpVerified ? (
                                    <a>
                                        <div className='d-flex justify-content-center mb-3 mt-3'>
                                            <button
                                                type='submit'
                                                onClick={handlePasswordUpdate}
                                                className='btn btn-md btn-warning'
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </a>
                                ) : (
                                    <a>
                                        <div className='d-flex justify-content-center mb-3 mt-3'>
                                            <button
                                                type='submit'
                                                onClick={otp ? handleVerify : handleSubmit}
                                                className='btn btn-md btn-warning'
                                            >
                                                Proceed
                                            </button>
                                        </div>
                                    </a>
                                )}
                            </div>

                        </Form >
                        <div className='text-center'>
                            <p style={{ color: 'grey' }}><strong></strong><Link to='/login'><strong>Login</strong></Link></p>
                        </div>
                        <div className='text-center'>
                            <p style={{ color: 'grey' }}><strong>Don't have a account ?</strong><Link to='/sign-up'><strong>Sign-Up</strong></Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePassword



// < form action = "" >
//                 <div className="inputs-login row">
//                     <div className="input-login">
//                         <input
//                             type="email"
//                             value={email}
//                             onChange={e => setEmail(e.target.value)}
//                             required
//                             placeholder='Email' />
//                     </div>
//                     {
//                         otp ? (
//                             <div className="input-login">
//                                 <input
//                                     type="text"
//                                     value={enteredOtp}
//                                     onChange={e => setEnteredOtp(e.target.value)}
//                                     required
//                                     placeholder='Enter the otp' />
//                             </div>
//                         ) : (
//                             ''
//                         )
//                     }
//                     {
//                         otpVerified ? (
//                             <>
//                                 <div className='input-login'>
//                                     <input
//                                         type='password'
//                                         value={password}
//                                         onChange={(e) => setPassword(e.target.value)}
//                                         required
//                                         placeholder='Enter new password'
//                                     />
//                                 </div>
//                             </>
//                         ) : (
//                             ''
//                         )
//                     }

//                 </div>
//                 <div className="submit-container-login mt-5">

//                     <a href="/" >
//                         <button type='button' className="btn btn-md btn-secondary">Cancel</button>
//                     </a>
//                     {otpVerified ? (
//                         <a>
//                             <button
//                                 type='submit'
//                                 onClick={handlePasswordUpdate}
//                                 className='btn btn-md btn-danger'
//                             >
//                                 Submit
//                             </button>
//                         </a>
//                     ) : (
//                         <a>
//                             <button
//                                 type='submit'
//                                 onClick={otp ? handleVerify : handleSubmit}
//                                 className='btn btn-md btn-danger'
//                             >
//                                 Proceed
//                             </button>
//                         </a>
//                     )}
//                 </div>
//                 <div className="app">
//                     <p className='text-center' >Create new account ?
//                         <a type='button' style={{ color: 'red' }} >Sign-Up</a>
//                     </p>
//                 </div>
//             </form >

//     <Form className='mt-3' >
//     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//         <Form.Control
//             className='input-login'
//             type="text"
//             value={}
//             onChange={e => setUsername(e.target.value)}
//             placeholder="Enter your username" />
//     </Form.Group>
//     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//         <Form.Control
//             className='input-login'
//             type="password"
//             value={password}
//             onChange={e => setPassword(e.target.value)}
//             placeholder="Enter your password" />
//     </Form.Group>
//     <div className='d-flex justify-content-center mb-3 mt-3'>
//         <button className='btn btn-warning text-center'>Submit</button>
//     </div>
// </Form>