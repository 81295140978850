import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import apiUrls from '../../Api';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

const DatatablePage = () => {
    const Institute = apiUrls.AllInstitute
    const [uniData, setUniData] = useState([])
    useEffect(() => {
        axios.get(Institute)
            .then((response) => {
                setUniData(response.data)
            })
    }, [])

    const formatDataForCSV = () => {
        const csvData = uniData.map((uni) => ({
            Institute: uni.institute_name || '',
            University: uni.university || '',
            City: uni.city || '',
            Taluka: uni.taluka || '',
            Email: uni.email || '',
            Mobile: uni.contact_no || '',
            Characteristics: uni.Characteristics || ''
        }));
        return csvData;
    };


    return (

        <div>
            <div className="mb-2">
                <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
                    <button className="btn btn-warning">Download</button>
                </CSVLink>
            </div>
            <MDBDataTable

                striped
                bordered
                responsive
                noBottomColumns
                hover
                data={{
                    columns: [
                        {
                            label: 'Institute',
                            field: 'institute_name',
                            sort: 'asc',

                        },
                        {
                            label: 'University',
                            field: 'university',
                            sort: 'asc',

                        },
                        {
                            label: 'District',
                            field: 'city',
                            sort: 'asc',

                        },
                        {
                            label: 'Taluka',
                            field: 'taluka',
                            sort: 'asc',

                        },
                        {
                            label: 'Email',
                            field: 'email',
                            sort: 'asc',

                        },
                        {
                            label: 'Mobile',
                            field: 'contact_no',
                            sort: 'asc',

                        },
                        {
                            label: 'Type',
                            field: 'Characteristics',
                            sort: 'asc',

                        },
                        {
                            label: 'View',
                            field: 'view',
                            sort: 'asc',

                        }
                    ],
                    rows: uniData.map((uni) => ({
                        institute_name: uni.institute_name,
                        university: uni.university,
                        city: uni.city,
                        taluka: uni.taluka,
                        email: uni.email,
                        contact_no: uni.contact_no,
                        Characteristics: uni.Characteristics,
                        view: (
                            <Link
                                to={`/students-list/${uni.id}`} // Assuming uni.id is the university ID
                                className="btn btn-warning"
                            >
                                View
                            </Link>
                        )
                    }))
                }}
            />
        </div>
    );
}

export default DatatablePage;