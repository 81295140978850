import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import apiUrls from '../../Api';

const Review = () => {
    const userData = useSelector((state) => state.user);
    const reviewData = JSON.parse(sessionStorage.getItem('reviewData'));
    const [token, setToken] = useState('')
    const ICICIAuthUrl = apiUrls.authTokenGenerate
    const CreateBaseTransaction = apiUrls.createbasetransaction
    const [transactionID, setTransactionID] = useState('')

    const nationalRequest = {   // constant values for national request
        MerchantID: "NTLINBKAGR",
        CurrencyType: 'INR',
        TypeField1: 'R',
        UserID: 'NTLINIRIBL-NA',
        TypeField2: 'F',
        AdditionalInfo2: 'IRIB',
    };

    const SweetAlertFunction = (title, text, icon, confirmButtonText) => {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: confirmButtonText
        });
    };

    const handlePayment = () => {   // payment logic starts from here 
        const newUuid = uuidv4(); // to generate uuid
        const returnURL = 'https://admin.svyuvasuraksha.org/returnTransactionNational.php';
        const returnURlICICi = 'https://app.svyuvasuraksha.org/paymentStatus'
        sessionStorage.setItem('Company', reviewData.companyname)
        if (reviewData.companyname === "ICICI Lombard GIC") {
            //  first we will hit the transaction api and then we will redirect to the icici payment portal
            const createBody = {
                TransactionId: newUuid,
                Amount: reviewData.premium,   // to send
                ApplicationId: "155",
                ReturnUrl: returnURlICICi,    // to send
                AdditionalInfo1: newUuid,
                AdditionalInfo2: userData.code,  // to send
                AdditionalInfo3: "null",
                AdditionalInfo4: userData.phone_number,  // to send
                AdditionalInfo5: userData.email,  // to send
                AdditionalInfo6: userData.name,   // to send
                AdditionalInfo7: "null",
                AdditionalInfo8: "null",
                AdditionalInfo9: "null",
                AdditionalInfo10: "null",
                AdditionalInfo11: "null",
                AdditionalInfo12: "null",
                AdditionalInfo13: "null",
                AdditionalInfo14: "null",
                AdditionalInfo15: "null",
            }
            console.log('first')
            sessionStorage.setItem('transactionID', newUuid)

            axios.post(CreateBaseTransaction, createBody)
                .then((res) => {
                    console.log(res, "line 68")
                    if (res.status === 200) {
                        window.location.href = `${apiUrls.iciciRedirect}/pgi/payment/PaymentMethod?transactionid=${res.data}`
                        console.log(`${apiUrls.iciciRedirect}/pgi/payment/PaymentMethod?transactionid=${res.data}` , "line 73")
                    }
                    else {
                        SweetAlertFunction("Failed", "Server not responding", "error", "ok")
                    }
                    // if (res.data.status === 0) {
                    //     window.location.href = `${apiUrls.iciciRedirect}/pgi/payment/PaymentMethod?transactionid=${res.data.data}`
                    // } else {
                    //     SweetAlertFunction("Failed", "Server not responding", "error", "ok")
                    // }
                    // console.log(res.data);
                })
                .catch((err) => {
                    console.log(err)
                    SweetAlertFunction("Failed", "Server not responding", "error", "ok")
                })
        } else {
            const message = `${nationalRequest.MerchantID}|${newUuid}|NA|${reviewData.premium}|NA|NA|NA|${nationalRequest.CurrencyType}|NA|${nationalRequest.TypeField1}|${nationalRequest.UserID}|NA|NA|${nationalRequest.TypeField2}|${userData.code}|${nationalRequest.AdditionalInfo2}|NA|${userData.phone_number}|${userData.email}|${userData.name}|NA|${returnURL}|`;
            const secretKey = 'BzbwWGDCLuSpMWw7WpWl0KRjCaEt91M4';
            const checksum = CryptoJS.HmacSHA256(message, secretKey).toString(CryptoJS.enc.Hex);
            const finalChecksum = checksum.toUpperCase();
            const url = 'https://pgi.billdesk.com/pgidsk/PGIMerchantPayment';
            const finalMessage = `${message}${finalChecksum}`;
            const finalUrl = `${url}?msg=${finalMessage}`;
            window.location.href = finalUrl;
        }
    };
    return (
        <>
            <div className='container'>
                <div className='row mt-4'>
                    <div className='col-md-7 col-sm-12'>
                        <Card className='mt-5'>
                            <Card.Body>
                                <Card.Title>Enrollment Details</Card.Title>
                                <Table responsive bordered>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Product</th>
                                            <th>Insurance Comp</th>
                                            <th>No. of students</th>
                                            <th>Total Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{reviewData.orgName}</td>
                                            <td>{reviewData.product}</td>
                                            <td>{reviewData.companyname}</td>
                                            <td>{reviewData.totalStudents}</td>
                                            <td>{reviewData.premium}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <Card className='mt-5'>
                            <Card.Body>
                                <Card.Title>Organization Details</Card.Title>
                                <Table responsive bordered>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Number</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{userData.name}</td>
                                            <td>{userData.phone_number}</td>
                                            <td>{userData.email}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <button className='btn btn-warning mt-5 d-flex ms-auto me-auto' onClick={handlePayment}>Make Payment</button>
            </div>
        </>
    );
};

export default Review;
