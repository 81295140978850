import React, { useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';

function TopHeaderOne() {
  useEffect(() => {
    // Check if the Google Translate script is already loaded
    if (!window.google || !window.google.translate) {
      const addScript = document.createElement('script');
      addScript.async = true;
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      );
      document.body.appendChild(addScript);

      // Initialize Google Translate
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            autoDisplay: true,
            includedLanguages: 'en,hi,mr',
          },
          'google_translate_element'
        );
      };
    }
  }, []);

  return (
    <Navbar bg="light" data-bs-theme="light">
      <Container>
        <Navbar.Brand href="/" className="ms-2">
          <img src="/LOGO-01.svg" className="navbarImageSize" />
          <div className="ms-3">
            <p style={{ margin: 0, fontSize: '18px' }} className="topHeader-text">
              Government of Maharashtra,
            </p>
            <p style={{ margin: 0, fontSize: '18px' }} className="topHeader-text">
              Department of Higher and Technical Education
            </p>
            <h3 style={{ margin: 0, fontSize: '20px' }} className="media-text">
              <strong>Swami Vivekanand Yuva Suraksha Yojana</strong>
            </h3>
          </div>
        </Navbar.Brand>
        <Nav className="ms-auto">
          {/* <div id="google_translate_element" className="google-translate"></div> */}
        </Nav>
      </Container>
    </Navbar>
  );
}

export default TopHeaderOne;
