import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import apiUrls from '../Api';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
        display: false,
        labels: {
            display: false
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                fontSize: 14,
                max: 50
            }
        }],
        xAxes: [{
            barPercentage: 0.6,
            ticks: {
                beginAtZero: true,
                fontSize: 11
            }
        }]
    }
};

const LineChart = () => {
    const Role = sessionStorage.getItem('role');
    const [api, setApi] = useState(null);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (Role === '4') {
            const id = sessionStorage.getItem('uni_id');
            setApi(`${apiUrls.MultipleBarChart}?role=${Role}&Uni=${id}`);
        } else if (Role === '5') {
            const id = sessionStorage.getItem('inst_id');
            setApi(`${apiUrls.MultipleBarChart}?role=${Role}&Inst=${id}`);
        } else {
            setApi(apiUrls.MultipleBarChart);
        }
    }, [Role]);

    useEffect(() => {
        if (api) { // Check if api is set
            axios.get(api)
                .then(response => {
                    const data = response.data;
                    // console.log(response.data, "line chart");
                    const formattedData = {
                        labels: data.map(item => item.month),
                        datasets: [
                            {
                                label: 'University',
                                data: data.map(item => item.role_counts['4'] || 0),
                                backgroundColor: '#f3ba2f',
                            },
                            {
                                label: 'Institute',
                                data: data.map(item => item.role_counts['5'] || 0),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                label: 'Student',
                                data: data.map(item => item.role_counts['6'] || 0),
                                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                            },
                        ],
                    };
                    setChartData(formattedData);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [api]);

    return (
        <Card>
            <Card.Body>
                <Card.Title>Successful Login</Card.Title>
                <div style={{ width: "100%", height: "300px" }}>
                    {chartData && <Bar data={chartData} options={options} />}
                </div>
            </Card.Body>
        </Card>
    );
};

export default LineChart;
