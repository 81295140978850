import React, { useEffect, useState } from 'react';
import { Pie } from "react-chartjs-2";
import { Card } from 'react-bootstrap';
import apiUrls from '../Api';
import axios from 'axios';

const PieChart = () => {
    const [PieApi, setPieApi] = useState('');
    const [pieData, setPieData] = useState([]);
    const Role = sessionStorage.getItem('role');

    useEffect(() => {
        if (Role === '3') {
            const name = sessionStorage.getItem('name');
            const Pie = `${apiUrls.PieGraph}?role=${Role}&Inscomp=${name}`;
            setPieApi(Pie);
        }
        else if(Role === '4'){
            const id = sessionStorage.getItem('uni_id')
            const Pie = `${apiUrls.PieGraph}?role=${Role}&Uni=${id}`;
            setPieApi(Pie);
        }else if(Role === '5'){
            const id = sessionStorage.getItem('inst_id')
            const Pie = `${apiUrls.PieGraph}?role=${Role}&Inst=${id}`;
            setPieApi(Pie);
        }else{
            const Pie = `${apiUrls.PieGraph}`;
            setPieApi(Pie);
        }
    }, [Role]);

    useEffect(() => {
        if (PieApi) { // Check if PieApi is set before making the API call
            axios.get(PieApi)
                .then((response) => {
                    console.log("API Response:", response.data); // Log API response
                    setPieData(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching chart data:", error);
                });
        }
    }, [PieApi]); // Add PieApi as a dependency

    const userData = {
        labels: pieData.map((data) => data.Company_name),
        datasets: [
            {
                label: "Users Count",
                data: pieData.map((data) => data.sum),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    // "#50AF95",
                    "#f3ba2f",
                    "#2a71d0",
                ],
                borderColor: "white",
                borderWidth: 3,
                hoverOffset: 30
            },
        ],
    };

    const options = {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: -5,
                bottom: 10,
            }
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>Insurance Company</Card.Title>
                    <div className='circle-graph' style={{ width: "300px", height: "300px" }}>
                        <Pie data={userData} options={options} />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default PieChart;
