import React, { useEffect, useState } from 'react';
import { useFormData } from '../../Contexts/FormDataContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Document() {
    const { formData, updateFormData, dataUpdated, resetDataUpdated } = useFormData();
    const navigate = useNavigate();
    const inst_id = sessionStorage.getItem('inst_id');
    const [panNumber, setPanNumber] = useState('')
    const [panProof, setPanProof] = useState()
    const [gstNumber, setGstNumber] = useState('')
    const [gstProof, setGstProof] = useState('')
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const [panFileTypeError, setPanFileTypeError] = useState(false);
    const [gstFileTypeError, setGstFileTypeError] = useState(false);
    const [panFormatError, setPanFormatError] = useState(false);
    const [gstFormatError, setGstFormatError] = useState(false);

    useEffect(() => {     //************** to prevent the page from reloading */
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [panNumber]);

    function handleChangePan(event) {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf')) {
            setPanProof(file);
            setPanFileTypeError(false);
        } else {
            setPanFileTypeError(true);
        }
    }

    function handleChange(event) {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf')) {
            setGstProof(file);
            setGstFileTypeError(false);
        } else {
            setGstFileTypeError(true);
        }
    }

    function handlePanNumberChange(event) {
        const value = event.target.value;
        setPanNumber(value);
        if (!isValidPan(value)) {
            setPanFormatError(true);
        } else {
            setPanFormatError(false);
        }
    }

    function handleGstNumberChange(event) {
        const value = event.target.value;
        setGstNumber(value);
        if (!isValidGST(value)) {
            setGstFormatError(true);
        } else {
            setGstFormatError(false);
        }
    }

    useEffect(() => {
        setIsNextButtonDisabled(gstFormatError);
    }, [gstFormatError]);

    const handleNextClick = (e) => {
        e.preventDefault();
        updateFormData({ panNumber, gstNumber , gstProof , panProof });
    };

    function handleBackClick() {
        navigate('/insurance-company')
    }

    useEffect(() => {
        if (dataUpdated) {
            resetDataUpdated();
            const token = sessionStorage.getItem('token')
            if (!token) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Looks like you are not logged in!",
                    footer: '<strong>Redirecting to Login</strong>'
                });
                navigate("/login")
            } else {
                navigate('/enrollment-type');
            }
        }
    }, [dataUpdated, resetDataUpdated, formData, navigate]);

    function isValidPan(pan) {
        const regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return regex.test(pan);
    }

    function isValidGST(gst) {
        const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        return regex.test(gst);
    }

    return (
        <div className='container py-5'>
            <div className='d-flex justify-content-center mt-5'>
                <div className='main-div-kyc row justify-content-md-center'>
                    <div className='col-12 heading-box mt-5 text-center'>
                        <h4 className='heading-box-title mt-2'>Kyc details</h4>
                    </div>

                    <div className='text-center mt-4'>
                        <strong><h3 style={{ fontWeight: '800' }}>Upload Pan-Card Details</h3></strong>
                    </div>
                    <div className='mt-3'>
                        <Col xs={12} md={12}>
                            <Form>
                                <fieldset>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Control
                                                type="text"
                                                onChange={handlePanNumberChange}
                                                value={panNumber}
                                                placeholder="Enter Pan Number"
                                            />
                                            {panFormatError && <p style={{ color: 'red' }}>Please enter a valid PAN number.</p>}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formFile" className="mb-3">
                                            <Form.Control type="file"  onChange={handleChangePan} />
                                            {panFileTypeError && <p style={{ color: 'red' }}>File type not supported. Please upload only .jpg, .png, or .pdf files.</p>}
                                        </Form.Group>
                                    </Row>
                                    <div className='text-center mt-2'>
                                        <strong><h3 style={{ fontWeight: '800' }}>Upload Gst Details</h3></strong>
                                    </div>
                                    <Row className="mb-2 mt-3">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Control
                                                type="text"
                                                onChange={handleGstNumberChange}
                                                value={gstNumber}
                                                placeholder="Enter Gst Number"
                                            />
                                            {gstFormatError && <p style={{ color: 'red' }}>Please enter a valid GST number.</p>}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formFile" className="mb-3">
                                            <Form.Control type="file" onChange={handleChange} />
                                            {gstFileTypeError && <p style={{ color: 'red' }}>File type not supported. Please upload only .jpg, .png, or .pdf files.</p>}
                                        </Form.Group>
                                    </Row>
                                    <div className='displays-buttons mb-5'>
                                        <div className='mt-5'>
                                            <button
                                                type='button'
                                                className='btn btn-warning'
                                                onClick={handleBackClick}
                                            >
                                                Back
                                            </button>
                                        </div>
                                        <div className='mt-5'>
                                            <button
                                                type='button'
                                                className='btn btn-warning'
                                                disabled={isNextButtonDisabled}
                                                onClick={handleNextClick}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </Form>
                        </Col>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Document;
