import React from 'react'
import SignIn from './SignIn'
import FooterOne from '../Constants/FooterOne'
import TopHeaderOne from '../Constants/TopHeaderOne'

function Login() {
    return (
        <>
            <TopHeaderOne />
            <SignIn />
            <FooterOne />
        </>
    )
}

export default Login