import React from 'react'
import BarChart from '../Components/BarChart'
import PieChart from '../Components/PieChart'
import LineChart from '../Components/LineChart'
import DoughnutChart from '../Components/DoughnutChart'

const Chart = () => {
    const Role = sessionStorage.getItem('role')
    return (
        <div className='container'>
            <div className='mt-4 row'>
                <div className='col-lg-8 col-md-6 col-sm-12'>
                    {Role === '3' ? <BarChart /> : <BarChart />}
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <PieChart />
                </div>
            </div>
            {Role !== '3' && (
                <div className='mt-4 row'>
                    <div className='col-lg-8 col-md-6 col-sm-12'>
                        <LineChart />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <DoughnutChart />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Chart
