import React from 'react'
import TopHeaderOne from '../../Components/Constants/TopHeaderOne'
import FooterOne from '../../Components/Constants/FooterOne'
import StudLogin from './StudLogin'

function StudentsLogin() {
  return (
    <>
     <TopHeaderOne />
     <StudLogin/>
    <FooterOne/>
    </>
  )
}

export default StudentsLogin