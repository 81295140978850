import React, { useEffect, useState } from 'react';
import { useFormData } from '../../Contexts/FormDataContext';
import { SiMicrosoftexcel } from "react-icons/si";
import Form from 'react-bootstrap/Form';
import { FaUpload } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import formatFile from '../../Assets/Excel/student_-Form_Filling_Data_Stucture.xlsx'
import { Button, Modal, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import apiUrls, { Base_File_Path } from '../../Api';
import { useSelector } from 'react-redux';

function Upload() {
  const userData = useSelector((state) => state.user);
  const instTempApi = apiUrls.InstituteTempUrl
  const instApi = apiUrls.InstituteFinalUrl
  const uniApi = apiUrls.UniversityFinalUrl
  const uniTempApi = apiUrls.UniversityTempUrl
  const navigate = useNavigate()
  const errorFilePath = Base_File_Path // for saving  the path of the file 
  const { formData, updateFormData, dataUpdated, resetDataUpdated } = useFormData(); // context
  const [selectedFileName, setSelectedFileName] = useState('');
  // const [error, setError] = useState('');
  const [numberOfStudents, setNumberOfStudents] = useState('') // for storing the number of students
  const [planName, setPlanName] = useState(''); // storing the name of the plan for eg. accident or mediclaim
  const [selectedCompany, setSelectedCompany] = useState(''); // for storing the name of the selected company
  const [enrollmentType, setEnrollmentType] = useState(''); // for storing the type of policy i.e. named or unnamed
  const [instId, setInstId] = useState(''); //  for storing the institute id
  const [excefile, setExcelFile] = useState(); // for storing the excel file
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [students, setStudents] = useState(true)
  const [errorButton, setErrorButton] = useState(true)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [total, setTotal] = useState('')
  const [errorCount, setErrorCount] = useState('')
  const [userKey, setUserKey] = useState('')
  const [messages, setMessage] = useState({})
  const [errorFile, setErrroFile] = useState('') /// for saving the error file 
  /////////// this is for opening the modal section /////
  const [lgShow, setLgShow] = useState(false);
  const [netAmount, setNetAmount] = useState()
  const [gst, setGst] = useState()
  const [totalPremium, setTotalPremium] = useState()
  const [isChecked, setChecked] = useState(false); // this is for checkbox part 
  const [loading, setLoading] = useState(false);
  const [gstNo, setGstNo] = useState(''); // for capaturing gst number
  const [gstProof, setGstProof] = useState(); // for capaturing gst proof
  const [panNo, setPanNo] = useState(''); // for capaturing pan number
  const [panProof, setPanProof] = useState(); // for capaturing pan proof


  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);

  useEffect(() => {
    // Enable the "Next" button if a valid excelfile is selected
    setIsNextButtonDisabled(!excefile || error);
  }, [excefile, error]);
  useEffect(() => {
    setStudents(
      !numberOfStudents
    )
  }, [numberOfStudents])

  useEffect(() => {
    setInstId(sessionStorage.getItem('inst_id')) // storing the institute id 
  }, []); // Add an empty dependency array to run the effect only once

  function handleChange(event) {
    const file = event.target.files[0];
    setExcelFile(file)
    // Check if a file is selected
    if (file) {
      // Check if the selected file is an Excel file
      const isExcelFile = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      if (isExcelFile) {
        setSelectedFileName(file.name);
        setError('');
      } else {
        // Show an error if the selected file is not an Excel file
        setSelectedFileName('');
        setError('Please upload a valid Excel file.');
      }
    }
  }
  useEffect(() => {  // to retrive the data from context api
    setEnrollmentType(formData.typeName)
    setPlanName(formData.planName)
    setSelectedCompany(formData.selectedCompany)
    setGstNo(formData.gstNumber)
    setGstProof(formData.gstProof)
    setPanNo(formData.panNumber)
    setPanProof(formData.panProof)

  }, [formData])

  // function handleNavigate() {
  //   navigate('/review')

  // }

  const Role = sessionStorage.getItem('role')
  const handleDataSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (Role === "5") {

      // Your logic for handling form submission, for example, using Axios
      const formData = new FormData();
      formData.append('id', instId);
      formData.append('plan_name', planName);
      formData.append('companyname', selectedCompany);
      formData.append('datatypename', enrollmentType);
      formData.append('excelname', excefile);
      formData.append('noofstud', numberOfStudents)
      formData.append('pannumber', panNo);
      formData.append('panImg', panProof);
      formData.append('gstnumber', gstNo);
      formData.append('gstImg', gstProof);

      axios.post(instTempApi, formData)
        .then(response => {
          // Handle success, if needed
          console.log(response.data, "line 99")
          console.log(planName, "line 100")
          console.log(response.data.total_amt);
          setMessage(response.data)
          setNetAmount(response.data.Net_amt)
          setGst(response.data.GST)
          // setErrroFile(response.data.DuplicatedDataErrorFile)
          setTotalPremium(response.data.total_amt)
          setUserKey(response.data.key_log)
          const reviewData = {
            orgName: userData.name,
            product: planName,
            companyname: selectedCompany,
            datatypename: enrollmentType,
            totalStudents: response.data.total_row,
            premium: response.data.total_amt
          }
          sessionStorage.setItem('reviewData', JSON.stringify(reviewData))
          sessionStorage.setItem("userEnquiryKey", response.data.key_log)
        })
        .catch(error => {
          // Handle error, if needed
          console.error(error);
        });
    } else {
      const uni_id = sessionStorage.getItem('uni_id')
      const formData = new FormData();
      formData.append('id', uni_id);
      formData.append('plan_name', planName);
      formData.append('companyname', selectedCompany);
      formData.append('datatypename', enrollmentType);
      formData.append('excelname', excefile);
      formData.append('noofstud', numberOfStudents)
      formData.append('pannumber', panNo);
      formData.append('panImg', panProof);
      formData.append('gstnumber', gstNo);
      formData.append('gstImg', gstProof);

      axios.post(uniTempApi, formData)
        .then(response => {
          // Handle success, if needed
          console.log(response.data, "line 99")
          console.log(planName, "line 100")
          console.log(response.data.total_amt);
          setMessage(response.data)
          setNetAmount(response.data.Net_amt)
          setGst(response.data.GST)
          setTotalPremium(response.data.total_amt)
          setUserKey(response.data.key_log)
          const reviewData = {
            orgName: userData.name,
            product: planName,
            companyname: selectedCompany,
            datatypename: enrollmentType,
            totalStudents: response.data.total_row,
            premium: response.data.total_amt
          }
          sessionStorage.setItem("userEnquiryKey", response.data.key_log)
          sessionStorage.setItem('reviewData', JSON.stringify(reviewData))
        })
        .catch(error => {
          // Handle error, if needed
          console.error(error);
        });
    }
  };
  const handlefinalSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);
    if (Role === "5") {
      // Your logic for handling form submission, for example, using Axios
      navigate('/review')
    } else {
      navigate('/review')
    }
  };
  function handleBackClick() {
    navigate('/enrollment-type')
  }
  useEffect(() => {
    setErrorCount(messages.Error)
    setSuccess(messages.Successful_count)
    setTotal(messages.total_row)
    setErrroFile(messages.errorFile)

  }, [messages])

  useEffect(() => {
    setErrorButton(errorCount > 0);
  }, [errorCount]);

  useEffect(() => {
    // Add an event listener for beforeunload
    const handleBeforeUnload = (event) => {
      // Cancel the event to prevent the default behavior
      event.preventDefault();
      // Standard message, works on most browsers
      event.returnValue = '';
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  function downloadExcel() {
    window.open(formatFile)
  }
  function downloadError() {
    window.open(`${errorFilePath}${errorFile}`)
  }

  const handleCheckboxChange = () => {
    if (total === success) {
      setChecked(!isChecked);
    }
  };
  const handleCheckboxChangeUnNamed = () => {
    setChecked(!isChecked);
  };

  const termsAndConditionsText = `
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    

    `.trim();
  return (
    <>
      <div className='container py-5'>
        <div className='d-flex justify-content-center mt-5'>
          <div className='main-div row justify-content-md-center'>
            <div className='col-md-6 mb-2 heading-box mt-5 text-center'>
              <h4 className='heading-box-title mt-2'>Upload Data</h4>
            </div>
            <div className='col-md-2 mb-2 text-center format-button'>
              <button type='button' className='btn btn-warning' onClick={downloadExcel} >Format</button>
            </div>
            <div className='container'>
              <div className='row'>
                <div className='ms-3 col-md-3'>
                  <div className='upload-box '>
                    <div className='d-flex flex-wrap upload-box-none justify-content-center p-3'>
                      <h4>Upload the data in excel format</h4>
                      <SiMicrosoftexcel className='uploads-icons' />
                    </div>
                  </div>
                </div>
                {
                  enrollmentType === "Named" ? (
                    <div className='col-md-8'>
                      <Form onSubmit={handleDataSubmit}>
                        <Form.Group controlId="formFileLg" className="mb-3">
                          <Form.Label><strong>Upload File</strong></Form.Label>
                          <Form.Control type="file" className='upload-details-form' onChange={handleChange} />
                        </Form.Group>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {selectedFileName && <p style={{ color: 'grey' }}><strong>Selected File: {selectedFileName}</strong></p>}

                        <div className='display-buttons'>
                          <div className='mt-5'>
                            <button
                              type='button'
                              className='btn btn-warning'
                              onClick={handleBackClick}
                            >
                              Back
                            </button>
                          </div>
                          <div className='mt-5'>
                            <button className='btn btn-warning'
                              onClick={() => setLgShow(true)}
                              disabled={isNextButtonDisabled}><FaUpload /> Upload</button>
                          </div>
                        </div>
                      </Form>

                      {/* this is the code for modal  */}
                      <Modal size="lg"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg">
                        <Modal.Header closeButton>
                          <Modal.Title id="example-custom-modal-styling-title">Data Checking :</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='login-title text-center mt-3 d-flex justify-content-around p-2'>
                            <span>TotalCount : <strong>{total}</strong> </span>
                            <span>CorrectCount :<strong style={{ color: 'green' }}>{success}</strong> </span>
                            <span>Errors : <strong style={{ color: 'red' }}>{errorCount}</strong></span>
                            <span>
                              {errorButton && ( // Render the button only if errorButton is true
                                <button className='btn btn-warning ms-5' onClick={downloadError}>
                                  <FaUpload /> Download Error file
                                </button>
                              )}
                            </span>
                          </div>
                          <div className='mt-4'>
                            <h6><strong>For {planName} your net per life premium is {netAmount} according to {selectedCompany}</strong></h6>
                            <h5><strong>Net Amount </strong>: {netAmount} Rupees</h5>
                            <h5><strong>GST</strong> : {gst} Rupees</h5>
                            <h5><strong>Total Premium</strong> : {totalPremium} Rupees</h5>
                          </div>
                          <div className='checkbox d-flex align-items-top'>
                            <form onSubmit={handlefinalSubmit}>
                              <div className='checkbox d-flex align-items-top'>
                                <div className="form-check">
                                  <input className="form-check-input mt-4"
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    id="flexCheckDefault" />
                                  <label className="form-check-label" for="flexCheckDefault">
                                    <div className='scrollable-terms'>
                                      <div className='terms-text'>
                                        <p><strong>{termsAndConditionsText}</strong></p>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className='d-flex justify-content-around mt-3 mb-3'>
                                <button type='submit'
                                  disabled={!isChecked}
                                  className=' mt-3 btn btn-warning'>Submit</button>
                              </div>
                            </form>
                          </div>
                          {loading && (
                            <div className="loading-overlay">
                              <div className="text-center">
                                <Spinner
                                  className="loading-spinner"
                                  animation="border"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                                <p>Loading...</p>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* /// this is the end of code  for modal  */}

                    </div>) : (
                    <>
                      <div className='col-md-8'>
                        <div className='text-center'>
                          <h4>You have selected un-named policy</h4>
                          <div className='feature-bottom'></div>
                        </div>
                        <div>
                          <Form onSubmit={handleDataSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                              <Form.Label><strong>Enter the number of students</strong></Form.Label>
                              <Form.Control
                                type="text"
                                value={numberOfStudents}
                                onChange={e => setNumberOfStudents(e.target.value)}
                                placeholder="Enter number" />
                            </Form.Group>
                            <div className='display-buttons'>
                              <div className='mt-5'>
                                <button
                                  type='button'
                                  className='btn btn-warning'
                                  onClick={handleBackClick}
                                >
                                  Back
                                </button>
                              </div>
                              <div className='mt-5'>
                                <button disabled={students}
                                  className='btn btn-warning' onClick={() => setLgShow(true)}>Submit</button>
                              </div>
                            </div>
                            <div className='d-flex justify-content-around mt-3 mb-3'>


                              <Modal size="lg"
                                show={lgShow}
                                onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg">
                                <Modal.Header closeButton>
                                  <Modal.Title id="example-custom-modal-styling-title">Data Inserted :</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                  <div className='mt-4'>
                                    <h5><strong>Net Amount </strong>: {netAmount} Rupees</h5>
                                    <h5><strong>GST</strong> : {gst} Rupees</h5>
                                    <h5><strong>Total Premium</strong> : {totalPremium} Rupees</h5>
                                  </div>
                                  <form onSubmit={handlefinalSubmit}>
                                    <div className='checkbox d-flex align-items-top'>
                                      <div className="form-check">
                                        <input className="form-check-input mt-4"
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={handleCheckboxChangeUnNamed}
                                          id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault">
                                          <div className='scrollable-terms'>
                                            <div className='terms-text'>
                                              <p><strong>{termsAndConditionsText}</strong></p>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className='d-flex justify-content-around mt-3 mb-3'>
                                      <button
                                        disabled={!isChecked}
                                        className=' mt-3 btn btn-warning'>Submit</button>
                                    </div>
                                  </form>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={handleClose}>
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </Form>
                        </div>

                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Upload;
