import React from 'react'
import { Card } from 'react-bootstrap'
import StudWithInst from './StudWithInst'
import { useParams } from 'react-router-dom'

function InstWiseStud() {
  const params = useParams()
  const InstWiseStud = params.id
  return (
    <>
    <div className='container mt-4'>
                <div>
                    <Card >
                        <Card.Body>
                            <Card.Title>Student detail</Card.Title>
                            <StudWithInst InstWiseStud={InstWiseStud} />
                        </Card.Body>
                    </Card>


                </div>

            </div>
    </>
  )
}

export default InstWiseStud