import React from 'react'
import { Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import TestTable from "./TestTable"
import InstWithUni from '../UniWiseInst/InstWithUni';
import SingleInst from './SingleInst';
import InsuranceInst from './InsuranceInst';

const Institute = () => {
    const Role = sessionStorage.getItem('role')
    const id = sessionStorage.getItem('uni_id')

    return (
        <>
            <div className='container mt-4'>
                <div>
                    <Card>
                        <Card.Body>
                            <Card.Title>Institute Details</Card.Title>
                            {Role === '4' && <InstWithUni id={id} />}
                            { Role === '2' && <TestTable />}
                        { Role === '1' && <TestTable />}
                        {Role === '3' && <InsuranceInst />}
                        {Role === '5' &&  <SingleInst /> }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Institute


// {
//     Role === "4" ? (
//         <InstWithUni id={id} />
//     ) : (
//         Role === '1' || Role === '2' ? (
//             <TestTable />
//         ) : (
//             Role === "5" ? (
//                 <SingleInst />
//             ) : (
//                 Role === "3" && (
//                     <InsuranceInst />
//                 )
//             )
//         )
//     )
// }