import React, { useEffect, useState } from 'react'
import logo from '../../Assets/Images/IntegratedlogoJpg.jpg'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import apiUrls from '../../Api';
import { Select } from 'antd';

function UniSignUp() {
    const navigate = useNavigate();
    const SignUp = apiUrls.signUp
    const [universityName, setUniversityName] = useState(null)
    const [instituteName, setInstituteName] = useState("")
    const [instituteCode, setInstituteCode] = useState("")
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [address, setAddress] = useState("")
    const [district, setDistrict] = useState("")
    const [taluka, setTaluka] = useState("")
    const [region, setRegion] = useState("")
    const [minority, setMinority] = useState(null)
    const [exclusive, setExclusive] = useState(null)
    const [location, setLocation] = useState(null)
    const [linguistic, setLinguistic] = useState(null)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [cNumber, setCnumber] = useState('')
    const [cNumber2, setCnumber2] = useState('')
    const [principalName, setPrincipalName] = useState('')
    const [college, setCollege] = useState(null)
    const [university, setUniversity] = useState([])

    // Validation state
    const [errors, setErrors] = useState({})

    const minorityData = [
        { value: 'yes', name: 'Yes' },
        { value: 'no', name: 'No' },
    ]
    const exclusiveType = [
        { value: 'boys', name: 'Boys' },
        { value: 'girls', name: 'Girls' },
        { value: 'co-Education', name: 'Co-Education' },
    ]
    const locationType = [
        { value: 'urban', name: 'Urban' },
        { value: 'rural', name: 'Rural' },
        { value: 'tribal', name: 'Tribal' },
    ]
    const linguisticType = [
        { value: 'linguistic', name: 'Linguistic' },
        { value: 'religious', name: 'Religious' },
        { value: 'none', name: 'None' },
    ]
    const collegeType = [
        { value: 'aided', name: 'Aided' },
        { value: 'non-Aided', name: 'Non-Aided' },
        { value: 'govt.', name: 'Govt.' },
    ]

    useEffect(() => {
        axios.get(apiUrls.UniversityData)
            .then((response) => {
                console.log(response.data)
                setUniversity(response.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const validate = () => {
        let tempErrors = {};
        tempErrors.universityName = universityName ? "" : "Select a university";
        tempErrors.instituteName = instituteName ? "" : "Institute name is required";
        tempErrors.email = (email && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)) ? "" : "Valid email is required";
        tempErrors.number = (number && /^[0-9]{10}$/.test(number)) ? "" : "Valid 10 digit number is required";
        tempErrors.address = address ? "" : "Address is required";
        tempErrors.distict = district ? "" : "District is required";
        tempErrors.taluka = taluka ? "" : "Taluka is required";
        tempErrors.region = region ? "" : "Region is required";
        tempErrors.password = password ? "" : "Password is required";
        tempErrors.minority = minority ? "" : "Minority type is required";
        tempErrors.exclusive = exclusive ? "" : "Exclusive type is required";
        tempErrors.location = location ? "" : "Location type is required";
        tempErrors.linguistic = linguistic ? "" : "Linguistic type is required";
        tempErrors.college = college ? "" : "College type is required";
        tempErrors.password = (password && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{9,}$/.test(password)) ? "" : "Password must contain at least one letter and one number and must be more than 8 Character"
        tempErrors.confirmPassword = (password === confirmPassword )  ? "" : "Password and confirm password should be same";

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    }

    const handleSubmit = (e) => {
        console.log("hello")
        e.preventDefault();
        if (validate()) {
            console.log("hello 2")
            console.log({
                Username: instituteName,
                UniversityId: universityName,
                Address: address,
                Email: email,
                Mobile: number,
                Contact1: cNumber,
                Contact2: cNumber2,
                Password: password,
                ReligiousType: linguistic,
                Taluka: taluka,
                JdRegion: region,
                Pricipal: principalName,
                District: district,
                CollegeType: college,
                ExclusivelyType: exclusive,
                LocationType: location,
                MinorityType: minority
            })
            axios.post(SignUp, {
                Username: instituteName,
                UniversityId: Number(universityName),
                Address: address,
                Email: email,
                Mobile: number,
                Contact1: cNumber,
                Contact2: cNumber2,
                Password: password,
                ReligiousType: linguistic,
                Taluka: taluka,
                JdRegion: region,
                Pricipal: principalName,
                District: district,
                CollegeType: college,
                ExclusivelyType: exclusive,
                LocationType: location,
                MinorityType: minority
            })
                .then((res) => {
                    console.log(res.data)
                    if (res.data.message === "Data inserted successfully, and email sent.") {
                        Swal.fire({
                            icon: "success",
                            title: "You have been successfully Signed IN",
                            showClass: {
                                popup: `
                                animate__animated
                                animate__fadeInUp
                                animate__faster
                              `
                            },
                            hideClass: {
                                popup: `
                                animate__animated
                                animate__fadeOutDown
                                animate__faster
                              `
                            }
                        });
                        navigate("/")
                    } else if (res.data.message === "This Email already Exists") {
                        Swal.fire({
                            icon: "success",
                            title: "This Email already Exists",
                            showClass: {
                                popup: `
                                animate__animated
                                animate__fadeInUp
                                animate__faster
                              `
                            },
                            hideClass: {
                                popup: `
                                animate__animated
                                animate__fadeOutDown
                                animate__faster
                              `
                            }
                        });
                        navigate("/")
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "No response from Server",
                            showClass: {
                                popup: `
                                animate__animated
                                animate__fadeInUp
                                animate__faster
                              `
                            },
                            hideClass: {
                                popup: `
                                animate__animated
                                animate__fadeOutDown
                                animate__faster
                              `
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log(error)
                    Swal.fire({
                        icon: "error",
                        title: "No response from Server",
                        showClass: {
                            popup: `
                            animate__animated
                            animate__fadeInUp
                            animate__faster
                          `
                        },
                        hideClass: {
                            popup: `
                            animate__animated
                            animate__fadeOutDown
                            animate__faster
                          `
                        }
                    });

                });
        }
    }

    const handleUniIdChange = (selectedOption) => {
        setUniversityName(selectedOption)
    }

    const handleMinorityChange = (selectedOption) => {
        setMinority(selectedOption)
    }

    const handleExclusiveChange = (selectedOption) => {
        setExclusive(selectedOption)
    }

    const handleLocationChange = (selectedOption) => {
        setLocation(selectedOption)
    }

    const handleLinguisticChange = (selectedOption) => {
        setLinguistic(selectedOption)
    }
    const handleCollegeChange = (selectedOption) => {
        setCollege(selectedOption)
    }
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <div className='container'>
                <div className='row d-flex justify-content-center '>
                    <div className='image-div d-flex justify-content-center mt-3'>
                        <img src={logo} className='image-login' alt='' />
                    </div>
                    <div className='login-main-div mt-4'>
                        <div className='login-title text-center mt-3'>
                            <h4 className='mt-1'>Sign Up</h4>
                        </div>
                        <Form className='mt-3' onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Select
                                        name="Make"
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={filterOption}
                                        value={universityName}
                                        onChange={handleUniIdChange}
                                        className="width-specifiying"
                                        options={(university.length > 0 ? university : [{ id: 0, name: "No University Found" }]).map(uni => ({ key: `uni-${uni.id}`, value: uni.id, label: uni.universityname }))}
                                        placeholder="Select University"
                                    />
                                    {errors.universityName && <div className="text-danger">{errors.universityName}</div>}
                                </Col>
                                <Col>
                                    <Form.Control type='text'
                                        value={instituteName}
                                        onChange={e => setInstituteName(e.target.value)}
                                        placeholder="Institute name" />
                                    {errors.instituteName && <div className="text-danger">{errors.instituteName}</div>}
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} md={3}>
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={filterOption}
                                        value={exclusive}
                                        onChange={handleExclusiveChange}
                                        className="width-specifiying"
                                        options={exclusiveType.map(item => ({ key: `item-${item.value}`, value: item.value, label: item.name }))}
                                        placeholder="Exclusive Type"
                                    />
                                    {errors.exclusive && <div className="text-danger">{errors.exclusive}</div>}
                                </Col>
                                <Col xs={12} md={3}>
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={filterOption}
                                        value={location}
                                        onChange={handleLocationChange}
                                        className="width-specifiying"
                                        options={locationType.map(item => ({ key: `item-${item.value}`, value: item.value, label: item.name }))}
                                        placeholder="Location Type"
                                    />
                                    {errors.location && <div className="text-danger">{errors.location}</div>}
                                </Col>
                                <Col xs={12} md={3}>
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={filterOption}
                                        value={minority}
                                        onChange={handleMinorityChange}
                                        className="width-specifiying"
                                        options={minorityData.map(item => ({ key: `item-${item.value}`, value: item.value, label: item.name }))}
                                        placeholder="Minority Type"
                                    />
                                    {errors.minority && <div className="text-danger">{errors.minority}</div>}
                                </Col>
                                <Col xs={12} md={3}>
                                    <Select
                                        name="Make"
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={filterOption}
                                        value={linguistic}
                                        onChange={handleLinguisticChange}
                                        className="width-specifiying"
                                        options={linguisticType.map(item => ({ key: `item-${item.value}`, value: item.value, label: item.name }))}
                                        placeholder="Linguistic Type"
                                    />
                                    {errors.linguistic && <div className="text-danger">{errors.linguistic}</div>}
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control type='text'
                                        value={address}
                                        onChange={e => setAddress(e.target.value)}
                                        placeholder="Address" />
                                    {errors.address && <div className="text-danger">{errors.address}</div>}
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        value={district}
                                        onChange={e => setDistrict(e.target.value)}
                                        placeholder="District" />
                                    {errors.state && <div className="text-danger">{errors.state}</div>}
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        value={taluka}
                                        onChange={e => setTaluka(e.target.value)}
                                        placeholder="Taluka" />
                                    {errors.city && <div className="text-danger">{errors.city}</div>}
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        value={region}
                                        onChange={e => setRegion(e.target.value)}
                                        placeholder="JD Region" />
                                    {errors.pincode && <div className="text-danger">{errors.pincode}</div>}
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control type='email'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        placeholder="Email address" />
                                    {errors.email && <div className="text-danger">{errors.email}</div>}
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        value={number}
                                        onChange={e => setNumber(e.target.value)}
                                        placeholder="Mobile" />
                                    {errors.number && <div className="text-danger">{errors.number}</div>}
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control type='text'
                                        value={cNumber}
                                        onChange={e => setCnumber(e.target.value)}
                                        placeholder="Contact Number1" />
                                </Col>
                                <Col>
                                    <Form.Control type='text'
                                        value={cNumber2}
                                        onChange={e => setCnumber2(e.target.value)}
                                        placeholder="Contact Number2" />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control type='text'
                                        value={principalName}
                                        onChange={e => setPrincipalName(e.target.value)}
                                        placeholder="Principal Name" />
                                </Col>
                                <Col>
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={filterOption}
                                        value={college}
                                        onChange={handleCollegeChange}
                                        className="width-specifiying"
                                        options={collegeType.map(item => ({ key: `item-${item.value}`, value: item.value, label: item.name }))}
                                        placeholder="College Type"
                                    />
                                    {errors.college && <div className="text-danger">{errors.college}</div>}
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <Form.Control type='password'
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        placeholder="Enter Password" />
                                    {errors.password && <div className="text-danger">{errors.password}</div>}
                                </Col>
                                <Col>
                                    <Form.Control type='password'
                                        value={confirmPassword}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm Password" />
                                         {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-center mb-3 mt-3'>
                                <button className='btn btn-warning text-center'>Submit</button>
                            </div>
                        </Form>
                        <div className='text-center'>
                            <p style={{ color: 'grey' }}><strong>Have an account?</strong><Link to='/login'><strong>Login</strong></Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UniSignUp;
