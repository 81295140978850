import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import ProfileDropdown from './ProfileDropdown';

const TopHeader = ({ onToggleOffCanvas }) => {
  return (
    <Navbar expand="lg" sticky="top" bg="light" className="navbar-color">

      <Navbar.Brand href="/" className="ms-2 d-flex ">
        {/* <img src='/LOGO-01.svg' className='navbarImageSize' /> */}
        <div className="ms-3">
          <p style={{ margin: 0, fontSize: '16px' }} className="topHeader-text">
            Government of Maharashtra,
          </p>
          <p style={{ margin: 0, fontSize: '16px' }} className="topHeader-text">
            Department of Higher and Technical Education
          </p>
          <h3 style={{ margin: 0, fontSize: '21px' }} className="media-text">
            <strong>Swami Vivekanand Yuva Suraksha Yojana</strong>
          </h3>
        </div>
      </Navbar.Brand>
      <Nav className="ms-auto">
        <div className='last-navbar-flex'>
          <ProfileDropdown />
          <button variant="light" className="btn d-lg-none toggle-button" onClick={onToggleOffCanvas}>
            <FaBars />
          </button>
        </div>
        {/* <Button variant="light" className="d-none d-lg-inline" onClick={onToggleSidebar}>
            <FaBars />
          </Button> */}
      </Nav>

    </Navbar>
  );
};

export default TopHeader;
