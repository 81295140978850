import React, { useEffect, useState } from 'react'
import apiUrls from '../../Api'
import { CSVLink } from 'react-csv';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

function DetailsTable() {
  const [uniData, setUniData] = useState([]);
  const companyName = sessionStorage.getItem('name')
  const apiurl = `${apiUrls.UploadsDetails}?Inscomp=${companyName}`

  useEffect(() => {
    axios.get(apiurl)
      .then((response) => {
        console.log(response.data , "line 16")
        setUniData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching university data:', error);
      });
  }, []);

  const formatDataForCSV = () => {
    const csvData = uniData.map((uni) => ({
      Name: uni.name || '',
      Receipt: uni.receipt || '',
      Policy: uni.policy_receipt || '',
      Students: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud,
      Premium: uni.total_premium
    }));
    return csvData;
  };

  return (
    <>
      <div>
        <div className="mb-2">
          <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
            <button className="btn btn-warning">Download</button>
          </CSVLink>
        </div>
        <MDBDataTable
          striped
          bordered
          hover
          data={{
            columns: [
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
              },
              {
                label: 'Receipt',
                field: 'Receipt',
                sort: 'asc',
              },
              {
                label: 'Policy',
                field: 'Policy',
                sort: 'asc',
              },
              {
                label: 'Students',
                field: 'Students',
                sort: 'asc',
              },
              {
                label: 'Premium',
                field: 'Premium',
                sort: 'asc',
              }
            ],
            rows: uniData.map((uni) => ({
              Name: uni.name || '',
              Receipt: uni.receipt || '',
              Policy: uni.policy_receipt || '',
              Students: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud,
              Premium: uni.total_premium
            }))
          }}
          responsive
          noBottomColumns
          className="fixed-header" // Add a custom class for styling
        />

      </div>
    </>
  )
}

export default DetailsTable