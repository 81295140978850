import React from 'react'
import PersonalProfile from './PersonalProfile'

const Profile = () => {
  return (
    <>
      <PersonalProfile />
    </>
  )
}

export default Profile