import React from 'react'
import Sidebar from './SideBar'
import Footer from './Constants/Footer'

const MainLayout = ({ children }) => {
    return (
        <div className="main-layout">
            <Sidebar>
                <div className="main-content">
                    {children}
                </div>
                <Footer />
            </Sidebar>
        </div>
    )
}

export default MainLayout