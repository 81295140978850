import React from 'react'
import DetailsTable from './DetailsTable'
import { Card } from 'react-bootstrap'

function InsuranceUploadDetails() {
  return (
    <>
        <div className='container mt-4'>
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>Uploads Detail</Card.Title>
                        <DetailsTable/>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </>
  )
}

export default InsuranceUploadDetails