export const UserData = [
    {
      id: 2,
      year: "January",
      userGain: 45677,
      userLost: 345,
    },
    {
      id: 3,
      year:"December" ,
      userGain: 78888,
      userLost: 555,
    },
    {
      id: 4,
      year: "November",
      userGain: 90000,
      userLost: 4555,
    },
    {
      id: 5,
      year: "October",
      userGain: 4300,
      userLost: 234,
    },
  ];
  