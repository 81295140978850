import React from 'react';
import { Card } from 'react-bootstrap';
import UniTestTable from './UniTestTable';
import SingleUni from './SingleUni';
import InsuranceUni from './InsuranceUni';

function University() {
    const Role = sessionStorage.getItem('role');

    return (
        <div className='container mt-4'>
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>University Detail</Card.Title>
                        {Role === '4' && <SingleUni />}
                        { Role === '2' && <UniTestTable />}
                        { Role === '1' && <UniTestTable />}
                        {Role === '3' && <InsuranceUni />}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default University;
