import React, { useEffect } from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Images/logo2-removebg-preview.png'
export default function PersonalProfile() {
    const userData = useSelector((state) => state.user);
        console.log(userData)
    const navigate = useNavigate()

    function handleLogout(){
        sessionStorage.clear()
        navigate('/login')
    }

  return (
    <section className="">
      <MDBContainer className="py-5 h-100 ">
        <MDBRow className=" h-100">
          <MDBCol lg="6" className="mb-4 mb-lg-0">
            <MDBCard className="mb-3 material-card" style={{ borderRadius: '.5rem' , height:"500px"}}>
              <MDBRow className="g-0" style={{ height:"500px"}}>
                <MDBCol md="4" className="gradient-custom text-center text-white"
                  style={{ borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
                  <MDBCardImage src={logo}
                    alt="Avatar" className="my-5" style={{ width: '80px' }} fluid />
                  <MDBTypography tag="h5" style={{textTransform:'uppercase'}}><strong>{userData.name}</strong></MDBTypography>
                  <MDBCardText className='pt-3' style={{textDecoration:'underline'}}><strong>{userData.user_member}</strong></MDBCardText>
                  {/* <MDBIcon far icon="edit mb-5" /> */}
                </MDBCol>
                <MDBCol md="8">
                  <MDBCardBody className="p-4">
                    <MDBTypography tag="h3">Information</MDBTypography>
                    <hr className="mt-0 mb-4" />
                    <MDBRow className="pt-1">
                      <MDBCol size="7" className="mb-3">
                        <MDBTypography tag="h6"><strong>Email :</strong></MDBTypography>
                        <MDBCardText className="text-muted">{userData.email}</MDBCardText>
                      </MDBCol>
                      <MDBCol size="5" className="mb-3">
                        <MDBTypography tag="h6"><strong>Phone :</strong></MDBTypography>
                        <MDBCardText className="text-muted">{userData.mobile}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                      
                    <MDBTypography tag="h4" className="pt-2">Address</MDBTypography>
                    <hr className="mt-0 mb-4" />
                    <MDBRow className="pt-1">
                      <MDBCol size="4" className="mb-3">
                        <MDBTypography tag="h6"><strong>State :</strong></MDBTypography>
                        <MDBCardText className="text-muted">{userData.state ? userData.state : "Null"}</MDBCardText>
                      </MDBCol>
                      <MDBCol size="4" className="mb-3">
                        <MDBTypography tag="h6"><strong>City :</strong></MDBTypography>
                        <MDBCardText className="text-muted">{userData.city ? userData.city : "Null"}</MDBCardText>
                      </MDBCol>
                      <MDBCol size="4" className="mb-3">
                        <MDBTypography tag="h6"><strong>Pincode :</strong></MDBTypography>
                        <MDBCardText className="text-muted">{userData.pincode ? userData.pincode : "Null"}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="pt-4">
                      <MDBCol size="" className="mb-3">
                        <button className='btn btn-danger' onClick={handleLogout}>Logout</button>
                      </MDBCol>
                    </MDBRow>
                    
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}