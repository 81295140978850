import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DashBoard from '../Pages/DashBoard';
import Institute from '../Pages/InstituteDetail';
import StudentDetails from '../Pages/InstWiseStud';
import Student from '../Pages/Student';
import Sidebar from '../Components/SideBar';
import Product from '../Pages/Product'
import ForgotPassword from '../Pages/ForgotPassword'
import Insurance from '../Pages/Insurance'
import EnrollmentType from '../Pages/EnrollmentType'
import UploadData from '../Pages/UploadData'
import Footer from '../Components/Constants/Footer';
import Login from '../Components/Login';
import Register from '../Pages/Register';
import Profile from '../Pages/Profile';
import University from '../Pages/University';
import UniWiseInst from '../Pages/UniWiseInst';
import SingleStudDetail from '../Pages/Student/SingleStudDetail';
import InstWiseStud from '../Pages/InstWiseStud';
import StudLogin from '../Pages/StudentsLogin/StudLogin';
import StudentsLogin from '../Pages/StudentsLogin';
import KycDetails from '../Pages/KycDetails';
import UploadInsurnace from '../Pages/UploadInsurnace';
import InsuranceUploadDetails from '../Pages/InsuranceUploadDetails';
import UploadEcard from '../Pages/UploadEcard';
import UploadStuEcard from '../Pages/UploadEcard/UploadStuEcard';
import PaymentStatus from '../Pages/PaymentStatus/PaymentStatus';
import Review from '../Pages/Review/Review';
import MainLayout from '../Components/MainLayout';

// Import your Sidebar component

function Router() {
  // const [logoutTrigger, setLogoutTrigger] = useState(false);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   let timeoutId;

  //   const logoutAfterInactivity = () => {
  //     timeoutId = setTimeout(() => {
  //       sessionStorage.clear(); // Clear session storage
  //       setLogoutTrigger(true); // Trigger logout
  //       navigate('/login');
  //     }, 7 * 60 * 1000); // 7 minutes in milliseconds
  //   };

  //   const resetLogoutTimer = () => {
  //     clearTimeout(timeoutId);
  //     logoutAfterInactivity();
  //   };

  //   const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

  //   const eventListenerCallback = () => {
  //     resetLogoutTimer();
  //     // navigate('/'); // Reset the timer and stay on the current page
  //   };

  //   events.forEach(event => {
  //     window.addEventListener(event, eventListenerCallback);
  //   });

  //   logoutAfterInactivity();

  //   return () => {
  //     events.forEach(event => {
  //       window.removeEventListener(event, eventListenerCallback);
  //     });
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (logoutTrigger) {
  //   }
  // }, [logoutTrigger, navigate]);

  function PrivateRoute({ element }) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');

    useEffect(() => {
      if (!token) {
        // Redirect to the login page if the token is not present
        navigate('/login');
      }
    }, [token]);

    return token ? element : null;
  }

  return (

    <Routes>

      {/* {logoutTrigger && <Navigate to="/login" />} */}
      <Route path="/login" element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/sign-up' element={<Register />} />
      <Route path='/student-login' element={<StudentsLogin />} />
      <Route
        path="/*"
        element={
          <>
            <MainLayout>
              {/* Place Sidebar within BrowserRouter */}
              <Routes >
                <Route
                  path="/"
                  element={<PrivateRoute element={<DashBoard />} />}
                />
                <Route
                  path="/profile"
                  element={<PrivateRoute element={<Profile />} />}
                />
                <Route
                  path="/institute"
                  element={<PrivateRoute element={<Institute />} />}
                />
                <Route
                  path="/university-detail"
                  element={<PrivateRoute element={<University />} />}
                />
                <Route
                  path="/institute/student-details/:id"
                  element={<PrivateRoute element={<StudentDetails />} />}
                />
                <Route
                  path="/university/institute-details/:id"
                  element={<PrivateRoute element={<UniWiseInst />} />}
                />
                <Route
                  path="/students-list/:id"
                  element={<PrivateRoute element={<InstWiseStud />} />}
                />
                <Route
                  path="/student-details/:id"
                  element={<PrivateRoute element={<SingleStudDetail />} />}
                />
                <Route
                  path="/Student"
                  element={<PrivateRoute element={<Student />} />}
                />
                <Route
                  path="/enrollment"
                  element={<PrivateRoute path="/enrollment" element={<Product />} />}
                />
                <Route
                  path="/insurance-company"
                  element={<PrivateRoute path="/insurance-company" element={<Insurance />} />}
                />
                <Route
                  path="/kyc-upload"
                  element={<PrivateRoute path="/kyc-upload" element={<KycDetails />} />}
                />
                <Route
                  path="/upload-details"
                  element={<PrivateRoute path="/upload-details" element={<InsuranceUploadDetails />} />}
                />
                <Route
                  path="/upload-eCard"
                  element={<PrivateRoute path="/upload-eCard" element={<UploadStuEcard />} />}
                />

                <Route
                  path="/enrollment-type"
                  element={<PrivateRoute path="/enrollment-type" element={<EnrollmentType />} />}
                />
                <Route
                  path="/upload-document"
                  element={<PrivateRoute path="/upload-document" element={<UploadInsurnace />} />}
                />
                <Route
                  path="/uploadData"
                  element={<PrivateRoute path="/uploadData" element={<UploadData />} />}
                />
                <Route
                  path="/review"
                  element={<PrivateRoute path="/review" element={<Review />} />}
                />
                <Route
                  path="/paymentStatus"
                  element={<PrivateRoute path="/paymentStatus" element={<PaymentStatus />} />}
                />
              </Routes>
              {/* <Footer /> */}
            </MainLayout>
          </>
        }
      />

    </Routes>

  );
}

export default Router;
