import React from 'react'
import Upload from './Upload'

function UploadData() {
  return (
    <>
    <Upload/>
    </>
  )
}

export default UploadData