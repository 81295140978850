import { MDBDataTable } from "mdbreact";
import apiUrls from "../../Api";
import { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from 'react-csv';

const InsuranceInst = () => {
    const Name = sessionStorage.getItem('name');
    const universityData = `${apiUrls.InsuranceUni}?Inscomp=${Name}&Inst`;
    const [uniData, setUniData] = useState([]);

    useEffect(() => {
        axios.get(universityData)
            .then((response) => {
                setUniData(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error('Error fetching university data:', error);
            });
    }, []);
    // institute_name: uni.institute_name,
    // company_name: uni.company_name,
    // product_name: uni.product_name,
    // type: uni.type,
    // total_students: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud,
    // total_premium: uni.total_premium,
    const formatDataForCSV = () => {
        const csvData = uniData.map((uni) => ({
            Institute: uni.institute_name || '',
            Company: uni.company_name || '',
            Product: uni.product_name || '',
            Type: uni.type || '',
            Students: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud,
            Premium: uni.total_premium || '',

        }));
        return csvData;
    };

    return (
        <div>
            <div className="mb-2">
                <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
                    <button className="btn btn-warning">Download</button>
                </CSVLink>
            </div>
            <MDBDataTable
                striped
                bordered
                hover
                data={{
                    columns: [
                        {
                            label: 'Institute',
                            field: 'institute_name',
                            sort: 'asc',
                        },
                        {
                            label: 'Company',
                            field: 'company_name',
                            sort: 'asc',
                        },
                        {
                            label: 'Plan',
                            field: 'product_name',
                            sort: 'asc',
                        },
                        {
                            label: 'Type',
                            field: 'type',
                            sort: 'asc',
                        },
                        {
                            label: 'Total Students',
                            field: 'total_students',
                            sort: 'asc',
                        },
                        {
                            label: 'Premium',
                            field: 'total_premium',
                            sort: 'asc',
                        },
                    ],
                    rows: uniData.map((uni) => ({
                        institute_name: uni.institute_name,
                        company_name: uni.company_name,
                        product_name: uni.product_name,
                        type: uni.type,
                        total_students: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud,
                        total_premium: uni.total_premium,
                    })),
                }}
                responsive
                noBottomColumns
            />
        </div>
    );
};

export default InsuranceInst;
