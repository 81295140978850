import React, { useEffect, useState } from 'react';
import { useFormData } from '../../Contexts/FormDataContext';
import policyAccidentImage from '../../Assets/Images/policy-type.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';

function Type() {
  const { formData, updateFormData, dataUpdated, resetDataUpdated } = useFormData();
  const navigate = useNavigate();
  const inst_id = sessionStorage.getItem('inst_id');
  const [typeName, setTypeName] = useState('');
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    // Enable the "Next" button if a typeName is selected
    setIsNextButtonDisabled(!typeName);
  }, [typeName]);

  const handleNextClick = (e) => {
    e.preventDefault();
    updateFormData({ typeName });
  };

  // Use useEffect to perform side effects after render
  useEffect(() => {
    // Check if data has been updated
    if (dataUpdated) {
      // Perform additional logic if needed
      console.log('Data has been updated in Type component!');
      console.log(formData);
      // Reset the flag
      resetDataUpdated();
      navigate('/uploadData');
    }
  }, [dataUpdated, resetDataUpdated]);

  useEffect(() => {
    // Add an event listener for beforeunload
    const handleBeforeUnload = (event) => {
      // Cancel the event to prevent the default behavior
      event.preventDefault();
      // Standard message, works on most browsers
      event.returnValue = '';
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [typeName]);
  function handleBackClick (){
    navigate('/kyc-upload')
  }
  return (
    <div className='container py-5'>
      <div className='d-flex justify-content-center mt-5'>
        <div className='main-div row justify-content-md-center'>
          <div className='col-12 heading-box mt-5 text-center'>
            <h4 className='heading-box-title mt-2'>Enrollment Policy Type</h4>
          </div>
          <div className='row justify-content-md-center'>
            <Col xs={12} md={4} className='enrollment-box-first'>
              <Image src={policyAccidentImage} className='image-enrollment' />
            </Col>
            <Col xs={12} md={8}>
              <Form>
                <fieldset>
                  <Form.Group as={Row} className="mb-3">
                    <Col sm={10}>
                      <Form.Check
                        type="radio"
                        label="Named Policy"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        value="Named"
                        onChange={(e) => setTypeName(e.target.value)}
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                        className="mb-2"
                      />
                      <Form.Check
                        type="radio"
                        label="Un-Named Policy"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios3"
                        value="unNamed"
                        onChange={(e) => setTypeName(e.target.value)}
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      />
                    </Col>
                    <Col xs={6} className='mt-5'>
                      <button
                        type='submit'
                        className='btn btn-warning'
                        onClick={handleBackClick}
                      >
                        Back
                      </button>
                    </Col>
                    <Col xs={6} className='mt-5'>
                      <button
                        type='submit'
                        className='btn btn-warning'
                        disabled={isNextButtonDisabled}
                        onClick={handleNextClick}
                      >
                        Next
                      </button>
                    </Col>
                  </Form.Group>
                </fieldset>
              </Form>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Type;
