import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { MdPolicy } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { IoSchool } from "react-icons/io5";
import { FaUniversity } from "react-icons/fa";
import apiUrls from '../Api';

const StatsCards = () => {
    const [data, setData] = useState({});
    const Role = sessionStorage.getItem('role');
    const Username = sessionStorage.getItem('name');

    useEffect(() => {
        let TotalApi;
        if (Role === '3') {
            const name = sessionStorage.getItem('name');
            TotalApi = `${apiUrls.TotalCountInsurance}?role=${Role}&Inscomp=${name}`;
        } else if (Role === '4') {
            const id = sessionStorage.getItem('uni_id');
            TotalApi = `${apiUrls.TotalMember}?Uni=${id}&role=${Role}`;
        } else if (Role === '5') {
            const id = sessionStorage.getItem('inst_id');
            TotalApi = `${apiUrls.TotalMember}?Inst=${id}&role=${Role}`;
        } else {
            TotalApi = apiUrls.TotalMember;
        }

        axios.get(TotalApi)
            .then((res) => {
                setData(res.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [Role]);

    return (
        <>
            <div className='container mt-3 '>
                <div className='row'>

                    <div className='col-md-3 col-sm-6'>
                        <Card className='dashboard-card-height' style={{ boxShadow: "0px 0px 10px #ffdd95" }}>
                            <Card.Body>
                                <div className='d-flex dashboard-main-order' >
                                    <div>
                                        <div className='text-muted fs-18'><h5>Insurance Company</h5></div>
                                        <h4 className='font-weight-bold'>{Role === '3' ? '1' : '2'}</h4>
                                    </div>
                                    <div >
                                        <MdPolicy className='icon-for-dashboard' />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    {Role !== '5' && (
                        <div className='col-md-3 col-sm-6'>
                            <Card className='dashboard-card-height' style={{ boxShadow: "0px 0px 10px #ffdd95" }}>
                                <Card.Body>
                                    <div className='d-flex dashboard-main-order' >
                                        <div>
                                            <div className='text-muted fs-18'><h5>University</h5></div>
                                            <h4 className='font-weight-bold'>{Role === '4' ? Username : data.UniActive}</h4>
                                        </div>
                                        <div >
                                            <FaUniversity className='icon-for-dashboard' />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    )}
                    
                        <div className='col-md-3 col-sm-6'>
                            <Card className='dashboard-card-height' style={{ boxShadow: "0px 0px 10px #ffdd95" }}>
                                <Card.Body>
                                    <div className='d-flex dashboard-main-order' >
                                        <div>
                                            <div className='text-muted fs-18'><h5>Institute</h5></div>
                                            <h4 className='font-weight-bold'>{Role === '5' ? Username : data.InstituteActive}</h4>
                                        </div>
                                        <div >
                                            <IoSchool className='icon-for-dashboard' />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    
                    <div className='col-md-3 col-sm-6'>
                        <Card className='dashboard-card-height' style={{ boxShadow: "0px 0px 10px #ffdd95" }}>
                            <Card.Body>
                                <div className='d-flex dashboard-main-order' >
                                    <div>
                                        <div className='text-muted fs-18'><h5>Students</h5></div>
                                        <h4 className='font-weight-bold'>{data.totalStud}</h4>
                                    </div>
                                    <div >
                                        <PiStudentBold className='icon-for-dashboard' />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatsCards;
