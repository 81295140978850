import { MDBDataTable } from "mdbreact";
import apiUrls from "../../Api";
import { useEffect, useState } from "react";
import { CSVLink } from 'react-csv';
import axios from "axios";

const InsuranceUni = () => {
    const Name = sessionStorage.getItem('name');
    const universityData = `${apiUrls.InsuranceUni}?Inscomp=${Name}&Uni`;
    const [uniData, setUniData] = useState([]);

    useEffect(() => {
        axios.get(universityData)
            .then((response) => {
                setUniData(response.data);
                // console.log(response.daa);
            })
            .catch((error) => {
                console.error('Error fetching university data:', error);
            });
    }, []);

    const formatDataForCSV = () => {
        const csvData = uniData.map((uni) => ({
            University: uni.uni_name || '',
            Company: uni.company_name || '',
            Plan: uni.product_name || '',
            Type:  uni.type || '',
            TotalStudents: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud || '',
            Premium:uni.total_premium || '',
            
        }));
        return csvData;
    };

    return (
        <div>
        <div className="mb-2">
            <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
                <button className="btn btn-warning">Download as CSV</button>
            </CSVLink>
        </div>
        <MDBDataTable
            striped
            bordered
            hover
            data={{
                columns: [
                    {
                        label: 'University',
                        field: 'uni_name',
                        sort: 'asc',
                    },
                    {
                        label: 'Company',
                        field: 'company_name',
                        sort: 'asc',
                    },
                    {
                        label: 'Plan',
                        field: 'product_name',
                        sort: 'asc',
                    },
                    {
                        label: 'Type',
                        field: 'type',
                        sort: 'asc',
                    },
                    {
                        label: 'Total Students',
                        field: 'total_students',
                        sort: 'asc',
                    },
                    {
                        label: 'Premium',
                        field: 'total_premium',
                        sort: 'asc',
                    },
                ],
                rows: uniData.map((uni) => ({
                    uni_name: uni.uni_name,
                    company_name: uni.company_name,
                    product_name: uni.product_name,
                    type: uni.type,         
                    total_students: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud,
                    total_premium: uni.total_premium,
                })),
            }}
            responsive
            noBottomColumns
            className="fixed-header" // Add a custom class for styling
        />

    </div>
    );
};

export default InsuranceUni;
