import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import apiUrls from '../../Api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Button, Result } from 'antd';

const Payment = () => {
    const userData = useSelector((state) => state.user);
    const [paymentMessage, setPaymentMessage] = useState('');
    const paymentStatus = apiUrls.paymentStatus;
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState('');
    const [paymentData, setPaymentData] = useState({
        Role: 0,
        ID: "",
        Name: "",
        Number: "",
        Email: "",
        MerchantID: "",
        CustomerID: "",
        TxnReferenceNo: "",
        BankReferenceNo: "",
        TxnAmount: "",
        BankID: "",
        BankMerchantID: "",
        TxnType: "",
        CurrencyName: "",
        ItemCode: "",
        SecurityType: "",
        SecurityID: "",
        SecurityPassword: "",
        TxnDate: "",
        AuthStatus: "",
        SettlementType: "",
        AdditionalInfo1: "",
        AdditionalInfo2: "",
        AdditionalInfo3: "",
        AdditionalInfo4: "",
        AdditionalInfo5: "",
        AdditionalInfo6: "",
        AdditionalInfo7: "",
        AdditionalInfo8: "",
        AdditionalInfo9: "",
        AdditionalInfo10: "",
        ErrorStatus: "",
        ErrorDescription: "",
        PGIMasterErrorCode: "",
        PGIUserErrorCode: "",
        PGTransactionID: "",
        PGPaymentID: "",
        OrderID: "",
        Status: "",
        InsuranceCompany: "",
        key_log: "",
        paymentGatway: ""
    });

    const reviewData = JSON.parse(sessionStorage.getItem('reviewData'));
    const userEnquiryKey = sessionStorage.getItem('userEnquiryKey');
    const navigate = useNavigate();

    const SweetAlertFunction = (title, text, icon, confirmButtonText) => {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: confirmButtonText
        });
    };

    useEffect(() => {
        const msg = searchParams.get('msg');
        if (msg) {
            console.log(msg)
            setMessage(decodeURIComponent(msg));  // Decode URL-encoded message
        }
    }, [searchParams]);

    useEffect(() => {
        if (message) {
            const secretKey = 'BzbwWGDCLuSpMWw7WpWl0KRjCaEt91M4';  // this will change according to the merchant id provided through url
            const messageParts = message.split('|');
            const receivedChecksum = messageParts.pop();
            const recreatedMessage = messageParts.join('|');  // Recreate the message string without the checksum
            const checksum = CryptoJS.HmacSHA256(recreatedMessage, secretKey).toString(CryptoJS.enc.Hex);

            if (receivedChecksum === checksum.toUpperCase()) {
                let status = 'Failed';
                if (messageParts[14] === "0300") {
                    SweetAlertFunction("Success", "Transaction Successful", "success", "OK");
                    status = 'Success';
                } else if (messageParts[14] === "0399") {
                    SweetAlertFunction("Failed", "Invalid Authentication at Bank", "error", "OK");
                    status = 'Failed';
                } else if (messageParts[14] === "NA") {
                    status = 'Failed';
                    SweetAlertFunction("Failed", "Invalid Input in the Request Message", "error", "OK");
                } else if (messageParts[14] === "0002") {
                    status = 'Failed';
                    SweetAlertFunction("Failed", "Unable to ascertain the status of the txn", "error", "OK");
                } else if (messageParts[14] === "0001") {
                    status = 'Failed';
                    SweetAlertFunction("Failed", "Error at Payment gateway", "error", "OK");
                } else {
                    status = 'Failed';
                    SweetAlertFunction("Failed", "Invalid Request", "error", "OK");
                }
                setPaymentMessage(status)
                const updatedPaymentData = {
                    ...paymentData,
                    MerchantID: messageParts[0],
                    CustomerID: messageParts[1],
                    TxnReferenceNo: messageParts[2],
                    BankReferenceNo: messageParts[3],
                    TxnAmount: messageParts[4],
                    BankID: messageParts[5],
                    BankMerchantID: messageParts[6],
                    TxnType: messageParts[7],
                    CurrencyName: messageParts[8],
                    ItemCode: messageParts[9],
                    SecurityType: messageParts[10],
                    SecurityID: messageParts[11],
                    SecurityPassword: messageParts[12],
                    TxnDate: messageParts[13],
                    AuthStatus: messageParts[14],
                    SettlementType: messageParts[15],
                    AdditionalInfo1: messageParts[16],
                    AdditionalInfo2: messageParts[17],
                    AdditionalInfo3: messageParts[18],
                    AdditionalInfo4: messageParts[19],
                    AdditionalInfo5: messageParts[20],
                    AdditionalInfo6: messageParts[21],
                    AdditionalInfo7: messageParts[22],
                    ErrorStatus: messageParts[23],
                    ErrorDescription: messageParts[24],
                    CheckSum: receivedChecksum,
                    Role: Number(userData.role),
                    ID: userData.id,
                    Name: userData.name,
                    Number: userData.phone_number,
                    Email: userData.email,
                    Status: status,
                    InsuranceCompany: reviewData.companyname,
                    key_log: userEnquiryKey,
                };

                setPaymentData(updatedPaymentData);


            } else {
                Swal.fire({
                    title: 'Failed',
                    text: 'CheckSum Invalid',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }
    }, [message]);
    const paymentVerification = apiUrls.paymentVerification
    useEffect(() => {
        if (reviewData.companyname === 'ICICI Lombard GIC') {
            let paymentStatus = ""
            const transactionID = sessionStorage.getItem("transactionID")
            axios.post(paymentVerification, { TransactionId: transactionID })
                .then((res) => {
                    console.log(res)
                    if (res.data.status === 0) {
                        console.log('line 170 to indicate that response is coming')
                        if (res.data.data.status === 0) {
                            SweetAlertFunction("Success", "Transaction is successful", "success", "ok")
                            paymentStatus = "Success"
                        } else {
                            paymentStatus = "Failed"
                            SweetAlertFunction("Failed", "Transaction failed", "error", "ok")
                        }
                        setPaymentMessage(paymentStatus)
                        const updatedPaymentData = {
                            ...paymentData,
                            CustomerID: transactionID,
                            TxnAmount: res.data.data.Amount,
                            Status: paymentStatus,
                            PGTransactionID: res.data.data.PGtransactionId,
                            paymentGatway: res.data.data.GatewayName,
                            PGPaymentID: res.data.data.GatewayId,
                            MerchantID: res.data.data.MerchantId,
                            Role: Number(userData.role),
                            ID: userData.id,
                            Name: userData.name,
                            Number: userData.phone_number,
                            Email: userData.email,
                            InsuranceCompany: reviewData.companyname,
                            key_log: userEnquiryKey,
                        }
                        setPaymentData(updatedPaymentData)
                    } else {
                        console.log("to indicate the error")
                        SweetAlertFunction("Failed", "Failed to get the response", "error", "okay")
                    }
                })
        }
    }, [reviewData])
    useEffect(() => {
        const postPaymentData = async () => {
            if (paymentData.Role > 0 && paymentData.MerchantID !== "") {
                console.log(paymentData, "line164")
                try {
                    const response = await axios.post(paymentStatus, paymentData);
                    console.log(response, "line 154");
                } catch (error) {
                    console.log(error);
                }
            }
        };
        postPaymentData();
    }, [paymentData]);
    const handleNavigate = () => {
        navigate('/')
    }
    return (
        <>
            <div className='payment-card'>
                <Result
                    className='mt-5 mb-5'
                    style={{ backgroundColor: "white", borderRadius: '5px' }}
                    status={paymentMessage === "Success" ? "success" : "error"}
                    // status="success"
                    title={paymentMessage === "Success" ? "Successfully Purchased" : "Transaction failed"}
                    subTitle={
                        <div className='d-flex flex-column'>
                            <span className="bold-text">Transaction ID: {paymentData.CustomerID}</span>
                            <span className="bold-text">Amount: ₹ {paymentData.TxnAmount}</span>
                        </div>
                    }
                    extra={[
                        <Button key="buy" onClick={handleNavigate} type="primary">Back Home</Button>,
                    ]}
                />
            </div>
        </>
    );
};

export default Payment;
