import React, { useState } from 'react';
import logo from '../../Assets/Images/IntegratedlogoJpg.jpg'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loginSuccess } from '../../Components/Redux/actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import apiUrls from '../../Api';

function StudLogin() {
    const loginApi = apiUrls.StudentLogin
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleLogin = (e) => {
        e.preventDefault();
        axios.post(loginApi, {
            username: username,
            password: password,
        })
            .then((response) => {
                console.log(response.data)

                if (response.data.success === true) {
                    sessionStorage.setItem('token', response.data.token)
                    sessionStorage.setItem('role', response.data.role)
                    sessionStorage.setItem('name' , response.data.name)              
                     Swal.fire({
                        icon: "success",
                        title: "You have been successfully logged IN",
                        showClass: {
                            popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                      `
                        },
                        hideClass: {
                            popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
                      `
                        },
                        customClass: {
                            popup: 'custom-swal-modal',
                        },

                    });
                    dispatch(loginSuccess(response.data));
                    
                    if (response.data.role === "6") {
                        sessionStorage.setItem('stud_id', response.data.id)
                        navigate(`/student-details/${response.data.id}`)
                    }

                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Invalid Email or Password",
                        showClass: {
                            popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                      `
                        },
                        hideClass: {
                            popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
                      `
                        },
                        customClass: {
                            popup: 'custom-swal-modal',
                        },

                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <>
            <div className='container'>
                <div className='row d-flex justify-content-center '>
                    <div className='image-div d-flex justify-content-center mt-3'>
                        <img src={logo} className='image-login' />
                    </div>
                    <div className='login-main-div mt-4'>
                        <div className='login-title text-center mt-3'>
                            <h4 className='mt-1'>Student Login</h4>
                        </div>
                        <Form className='mt-3' onSubmit={handleLogin}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    className='input-login'
                                    type="text"
                                    value={username}

                                    onChange={e => setUsername(e.target.value)}
                                    placeholder="Enter your name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Control
                                    className='input-login'
                                    type="text"
                                    value={password}

                                    onChange={e => setPassword(e.target.value)}
                                    placeholder="Enter your email/phone/D-M-YYYY" />
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Control
                                    className='input-login'
                                    type="text"
                                    value={password}

                                    onChange={e => setPassword(e.target.value)}
                                    placeholder="Enter your dob " />
                            </Form.Group> */}
                            <div className='d-flex justify-content-center mb-3 mt-3'>
                                <button className='btn btn-warning text-center'>Submit</button>
                            </div>
                        </Form>
                        <div className='text-center'>
                            <p style={{ color: 'grey' }}><strong></strong><Link to='/forgot-password'><strong>Forget Password</strong></Link></p>
                        </div>
                        {/* <div className='text-center'>
                            <p style={{ color: 'grey' }}><strong>Don't have a account ?</strong><Link to='/sign-up'><strong>Sign-Up</strong></Link></p>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudLogin