// store.js
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import appReducer from './reducer';

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage, // storage can be imported from 'redux-persist/lib/storage', or use AsyncStorage in react-native
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, appReducer);

// Create the store with persisted reducer
const store = createStore(persistedReducer);

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
