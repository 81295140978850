import React from 'react'
import UploadStuEcard from './UploadStuEcard'

function UploadEcard() {
    return (
        <>
            <UploadStuEcard />
        </>
    )
}

export default UploadEcard;