import React, { useEffect, useState } from 'react';
import { useFormData } from '../../Contexts/FormDataContext';
import policyAccidentImage from '../../Assets/Images/personal-accident-policy-service.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Plan() {
  const { formData, updateFormData, dataUpdated, resetDataUpdated } = useFormData();
  const navigate = useNavigate();
  const inst_id = sessionStorage.getItem('inst_id');
  const [planName, setPlanName] = useState('');
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    // Enable the "Next" button if a planName is selected
    setIsNextButtonDisabled(!planName);
  }, [planName]);

  const handleNextClick = (e) => {
    e.preventDefault();
    updateFormData({ planName });
    
  };
  function handleBackClick (){
    navigate('/')
  }

  useEffect(() => {
    // Check if data has been updated
    if (dataUpdated) {
      // Perform additional logic if needed
      console.log('Data has been updated in Plan component!');
      console.log(formData);
      // Reset the flag
      resetDataUpdated();
      const token = sessionStorage.getItem('token')
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Looks like you are no logged in!",
        footer: '<strong>Redirecting to Login</strong>'
      });
      navigate("/login")
    }else{
      navigate('/insurance-company');
    }
      
    }
  }, [dataUpdated, resetDataUpdated, formData, navigate]);

  return (
    <div className='container py-5'>
      <div className='d-flex justify-content-center mt-5'>
        <div className='main-div row justify-content-md-center'>
          <div className='col-12 heading-box mt-5 text-center'>
            <h4 className='heading-box-title mt-2'>Select Product</h4>
          </div>
          <div className='row justify-content-md-center'>
            <Col xs={12} md={4} className='enrollment-box-first'>
              <Image src={policyAccidentImage} className='image-enrollment' />
            </Col>
            <Col xs={12} md={6}>
              <Form>
                <fieldset>
                  <Form.Group as={Row} className="mb-3">
                    <Col xs={12}>
                      <Form.Check
                        type="radio"
                        label="Personal Accident Only"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        value="Personal Accident Only"
                        onChange={(e) => setPlanName(e.target.value)}
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                        className="mb-2"
                      />
                      <Form.Check
                        type="radio"
                        label="Personal Accident + Mediclaim Policy"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios3"
                        value="Personal Accident + Mediclaim Policy"
                        onChange={(e) => setPlanName(e.target.value)}
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      />
                    </Col>
                    <Col xs={6} className='mt-5'>
                      <button
                        type='button'
                        className='btn btn-warning'
                        onClick={handleBackClick}
                      >
                        Back
                      </button>
                    </Col>
                    <Col xs={6} className='mt-5'>
                      <button
                        type='submit'
                        className='btn btn-warning'
                        onClick={handleNextClick}
                        disabled={isNextButtonDisabled} // Disable the button when no option is selected
                      >
                        Next
                      </button>
                    </Col>
                  </Form.Group>
                </fieldset>
              </Form>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plan;
