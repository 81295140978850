import React from 'react'
import DocumentsUpload from './DocumentsUpload'

function UploadInsurnace() {
    return (
        <>
            <DocumentsUpload />
        </>
    )
}

export default UploadInsurnace