import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import apiUrls from '../../Api';
import axios from 'axios';
import Swal from 'sweetalert2';

function SingleStudDetail() {
    const [student, setStudent] = useState([]);
    const params = useParams();
    const studentId = params.id ? parseInt(params.id) : parseInt(sessionStorage.getItem('stud_id'));


    const singleStudentDetails = `${apiUrls.AllStudents}?id=${studentId}`;

    useEffect(() => {
        if (singleStudentDetails) {
            axios.get(singleStudentDetails)
                .then((response) => {
                    console.log(response.data);
                    setStudent(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching university data:', error);
                });
        }
    }, [singleStudentDetails]);

    function popup() {
        Swal.fire({
            icon: "question",
            title: "E-card not available...",
            showClass: {
                popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
            },
            hideClass: {
                popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
            },
            customClass: {
                popup: 'custom-swal-modal',
            },
        });
    }

    return (
        <>
            <div className='container mt-4 vh-100'>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-warning mb-3' onClick={popup}>Download E-Card</button>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <Card.Title>Student Details</Card.Title>
                            <Table responsive bordered>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Taluka</th>
                                        <th>Gender</th>
                                        <th>Date Of Birth</th>
                                        <th>University Name</th>
                                        <th>Institute Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {student.map((items, index) => (
                                        <tr key={index}>
                                            <td>{items.StudentName}</td>
                                            <td>{items.city}</td>
                                            <td>{items.Gender}</td>
                                            <td>{items.DOB}</td>
                                            <td>{items.universityname}</td>
                                            {/* Conditionally render institute name */}
                                            <td>{items.institute_name ? items.institute_name : 'NA'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                    {/* Only render insurance and nominee details if data is available */}
                    {student.length > 0 && (
                        <div className='row mt-4'>
                            <div className='col-md-6 col-sm-12'>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Insurance Details</Card.Title>
                                        <Table responsive bordered>
                                            <thead>
                                                <tr>
                                                    <th>SR</th>
                                                    <th>Insurance Company</th>
                                                    <th>Policy Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>{student[0].company_name}</td>
                                                    <td>{student[0].plan_name}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Nominee Details</Card.Title>
                                        <Table responsive bordered>
                                            <thead>
                                                <tr>
                                                    <th>SR</th>
                                                    <th>Name</th>
                                                    <th>Relation</th>
                                                    <th>Policy Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>{student[0].GuardianName}</td>
                                                    <td>{student[0].GuardianRelation}</td>
                                                    <td>{student[0].plan_name}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SingleStudDetail;
