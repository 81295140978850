import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './Components/SideBar';
import DashBoard from './Pages/DashBoard';
import Footer from './Components/Constants/Footer';
import Institute from './Pages/InstituteDetail';
import Student from './Pages/Student';
import Login from './Components/Login';
import Router from './Router/Router';

const App = () => {


  return (
    <>
     
        {/* <Sidebar/> */}
        <Router />
     
      {/* <Footer/> */}
    </>
  );
};

export default App;
