import React, { useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import { Card } from 'react-bootstrap';
import axios from 'axios';
import apiUrls from '../Api';
export const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
        display: false,
        labels: {
            display: false
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                fontSize: 14,
                max: 50
            }
        }],
        xAxes: [{
            barPercentage: 0.6,
            ticks: {
                beginAtZero: true,
                fontSize: 11
            }
        }]
    }
};
const BarChart = () => {
    const [ChartApi, setChartApi] = useState('')
    const Role = sessionStorage.getItem('role')

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        let ChartApiGraph = '';
        if (Role === '3') {
            const name = sessionStorage.getItem('name');
            ChartApiGraph = `${apiUrls.ChartGraph}?Inscomp=${name}&role=${Role}`;
        } else if (Role === '4') {
            const id = sessionStorage.getItem('uni_id');
            ChartApiGraph = `${apiUrls.ChartGraph}?role=${Role}&uni=${id}`;
        } else if (Role === '5') {
            const id = sessionStorage.getItem('inst_id');
            ChartApiGraph = `${apiUrls.ChartGraph}?role=${Role}&Inst=${id}`;
        } else {
            ChartApiGraph = apiUrls.ChartGraph;
        }
        setChartApi(ChartApiGraph);
    
        axios.get(ChartApiGraph)
            .then((res) => {
                console.log("API Response:", res.data);
                setChartData(res.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [Role, ChartApi]); // Include ChartApi in the dependency array
    


    // useEffect(() => {
    //     axios.get(ChartApi)
    //         .then((response) => {
    //             // console.log("API Response:", response.data); // Log API response
    //             setChartData(response.data);
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching chart data:", error);
    //         });
    // }, []);

    // console.log("chartData:", chartData); // Log chartData state

    const userData = {
        labels: chartData.map((data) => data.month),
        datasets: [
            {
                label: "Users Gained",
                data: chartData.map((data) => data.user_count),
                backgroundColor: [
                    "#2a71d0",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0",
                ],
                borderColor: "black",
                borderWidth: 1,
                hoverOffset: 30
            },
        ],
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>Student Data</Card.Title>
                    <div style={{ width: "100%", height: "300px" }}>
                        <Bar data={userData} options={options} />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default BarChart;
