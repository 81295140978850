import { MDBFooter } from 'mdb-react-ui-kit'
import React from 'react'

const Footer = () => {
    return (
        <>
             <MDBFooter  className='mt-5 text-center text-lg-left footer-main'>
                <div className='text-center p-3'>
                Copyright  &copy; {new Date().getFullYear()} Design & Developed by <a href="https://www.nivotime.com/" rel="noopener noreferrer" className="" target="_blank"> @NivoTimes</a>
                </div>
            </MDBFooter>
        </>
    )
}

export default Footer
