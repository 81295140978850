import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import apiUrls from '../Api';

const TableDashboard = () => {
    const [apiUrl, setApiUrl] = useState('');
    const [data, setData] = useState([]);

    const Role = sessionStorage.getItem('role');

    useEffect(() => {
        if (Role === '3') {
            const name = sessionStorage.getItem('name');
            const api = `${apiUrls.RecentData}?Inscomp=${name}&role=${Role}`;
            setApiUrl(api);
        }
        else if (Role === '4') {
            const id = sessionStorage.getItem('uni_id');
            const api = `${apiUrls.RecentData}?Uni=${id}&role=${Role}`;
            setApiUrl(api);
        }
        else if (Role === '5') {
            const id = sessionStorage.getItem('inst_id');
            const api = `${apiUrls.RecentData}?Inst=${id}&role=${Role}`;
            setApiUrl(api);
        }
        else {
            const api = `${apiUrls.RecentData}`;
            setApiUrl(api);
        }

    }, [Role]);

    useEffect(() => {
        if (apiUrl) {
            axios.get(apiUrl)
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [apiUrl]);

    return (
        <div className='container mt-4'>
            <Card>
                <Card.Body>
                    <Card.Title>Recent Institute Data</Card.Title>
                    {
                        Role !== '5' && (
                            <Table responsive striped hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Product</th>
                                        <th>Students</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.institute_name}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.type === 'Named' ? item.no_of_stud : item.total_stud}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )
                    }
                    {
                        Role === '5' && (
                            <Table responsive striped hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Company</th>
                                        <th>Product</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.StudentName}</td>
                                            <td>{item.company_name}</td>
                                            <td>{item.plan_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )
                    }
                </Card.Body>
            </Card>
        </div>
    );
};

export default TableDashboard;
