import React from 'react'
import { Card, Table } from 'react-bootstrap'
import AllStudents from './AllStudents'
import InsuranceStud from './InsuranceStud'
import { useNavigate } from 'react-router-dom'
import SingleStudDetail from './SingleStudDetail'

const Student = () => {
    const navigate = useNavigate()
const Role = sessionStorage.getItem('role')
const id = sessionStorage.getItem('stud_id')
// if(Role === "6"){
//         navigate(`/students-list/${id}`)
// }
    return (
        <>
            <div className='container mt-4'>
                <div>
                    <Card >
                        <Card.Body>
                            <Card.Title>Student Details</Card.Title>
                           {
                            Role==="3"?(
                                <InsuranceStud/>
                            ):(
                                <AllStudents/>
                            )
                           }
                           {/* {Role === '3' && <SingleStudDetail />} */}
                        </Card.Body>
                    </Card>


                </div>

            </div>
        </>
    )
}

export default Student