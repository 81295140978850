// reducer.js
import { LOGIN_SUCCESS } from './actions';

const initialState = {
  user: null,
};

const appReducer = (state = initialState, action) => {
    console.log('Action:', action);
    console.log('Current State:', state);
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
      
  }
};

export default appReducer;
