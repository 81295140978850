import React from 'react'
import { useParams } from 'react-router-dom'
import InstWithUni from './InstWithUni'
import { Card } from 'react-bootstrap'

function UniWiseInst() {
    const params = useParams()
    const universityid = params.id
  
  return (
    <>
     <div className='container mt-4'>
                <div>
                    <Card >
                        <Card.Body>
                            <Card.Title>Institute detail</Card.Title>
                            <InstWithUni id={universityid}/>
                        </Card.Body>
                    </Card>


                </div>

            </div>
    </>
  )
}

export default UniWiseInst