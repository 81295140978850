import React from 'react'
import Type from './Type'

function EnrollmentType() {
  return (
    <>
    <Type/>
    </>
  )
}

export default EnrollmentType