import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProfileDropdown = () => {
    const userData = useSelector((state) => state.user);
    const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle the state to show/hide the dropdown
  };

  const handleLogout = () => {
   sessionStorage.clear()
   navigate('/login')
  };

  return (
    <Dropdown show={showDropdown} align="end" onToggle={handleToggleDropdown}>
      <Dropdown.Toggle variant='warning' id="profile-dropdown" className="rounded-circle">
        <FaUserCircle style={{ fontSize: '25px' }} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item>{userData.name}</Dropdown.Item>
      <Dropdown.Item>{userData.email}</Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}><button className='btn btn-warning'>Logout</button></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
