import axios from 'axios';
import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap'; // Import Form from react-bootstrap
import apiUrls from '../../Api';
import Swal from 'sweetalert2';

function DocumentsUpload() {
    const [key, setKey] = useState('');
    const [org, setOrg] = useState('');
    const [receipt, setReceipt] = useState(null);
    const [policy, setPolicy] = useState(null);
    const apiUrl = apiUrls.Receipt

    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('key', key);
        formData.append('role', org);
        formData.append('receipt', receipt);
        formData.append('policy', policy);

        axios.post(apiUrl, formData)
            .then((res) => {
                console.log(res.data)
                if (res.data.success === true) {
                    Swal.fire({
                        title: "Success",
                        text: "Your data has been successfully uploaded",
                        footer: res.data.message,
                        icon: "success"
                    });
                    

                }
                else {
                    if (res.data.success === false) {
                        Swal.fire({
                            title: "Oops",
                            text: "Your data has not been successfully uploaded",
                            footer: res.data.message,
                            icon: "error"
                        });
                    }
                }
            })
    }

    function handleChangeFile(event, fileType) {
        const file = event.target.files[0];
        if (fileType === 'receipt') {
            setReceipt(file);
        } else if (fileType === 'policy') {
            setPolicy(file);
        }
    }

    return (
        <>
            <div className='container py-5'>
                <div className='d-flex justify-content-center mt-5'>
                    <div className='main-div-kyc row justify-content-md-center'>
                        <div className='col-12 heading-box mt-5 text-center'>
                            <h4 className='heading-box-title mt-2'>Upload Data</h4>
                        </div>
                        <div className='mt-5'>
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label><strong>Key</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={key}
                                            onChange={(e) => setKey(e.target.value)}
                                            placeholder="Enter the key..."
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label><strong>Type</strong></Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            className='upload-details-form'
                                            onChange={(event) => setOrg(event.target.value)}
                                            value={org}
                                        >
                                            <option>Choose One</option>
                                            <option value="4">University</option>
                                            <option value="5">Institute</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label><strong>Policy Copy</strong></Form.Label>
                                            <Form.Control type="file" onChange={(e) => handleChangeFile(e, 'policy')} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label><strong>Transaction Receipt</strong></Form.Label>
                                            <Form.Control type="file" onChange={(e) => handleChangeFile(e, 'receipt')} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className='display-buttons'>
                                    <div className='mt-3 mb-3'>
                                        <button
                                            type='submit'
                                            className='btn btn-warning'
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DocumentsUpload;
