import React, { useState, useEffect } from 'react';
import { FaBars, FaUserAlt } from 'react-icons/fa';
import { RiAdminFill } from "react-icons/ri";
import { FaBuildingColumns } from "react-icons/fa6";
import { PiStudentFill } from "react-icons/pi";
import { TbDatabaseImport } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import TopHeader from './Constants/TopHeader';
import { TbListDetails } from "react-icons/tb";
import { Offcanvas } from 'react-bootstrap';

const Sidebar = ({ children }) => {
    const [menuItem, setMenuItem] = useState([])
    const Role = sessionStorage.getItem('role')
    const [isOpen, setIsOpen] = useState(window.innerWidth >= 992);
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const id = sessionStorage.getItem("stud_id")
    const toggle = () => setIsOpen(!isOpen);
    const toggleOffCanvas = () => setShowOffCanvas(!showOffCanvas);

    useEffect(() => {
        
        if (Role === "1") {    // for administrators 
            const menuItem = [   
                { path: '/', name: 'Dashboard', icon: <RiAdminFill /> },
                { path: '/university-detail', name: 'University', icon: <FaBuildingColumns /> },

                { path: '/institute', name: 'Institute', icon: <FaBuildingColumns /> },
                {
                    path: '/student', name: 'Student', icon: <PiStudentFill />
                },

                // { path: '/enrollment', name: 'Enrollment', icon: <FaBuildingColumns /> },
                { path: '/profile', name: 'Profile', icon: <FaUserAlt /> }

            ];
            setMenuItem(menuItem)
        }
        if (Role === "2") {   // for Ministers
            const menuItem = [
                { path: '/', name: 'Dashboard', icon: <RiAdminFill /> },
                { path: '/university-detail', name: 'University', icon: <FaBuildingColumns /> },
                { path: '/institute', name: 'Institute', icon: <FaBuildingColumns /> },
                {
                    path: '/student', name: 'Student', icon: <PiStudentFill />
                },
                // { path: '/enrollment', name: 'Enrollment', icon: <FaBuildingColumns /> },
                { path: '/profile', name: 'Profile', icon: <FaUserAlt /> }

            ];
            setMenuItem(menuItem)
        }
        if (Role === "3") {  // for insurance company
            const menuItem = [
                { path: '/', name: 'Dashboard', icon: <RiAdminFill /> },
                { path: '/university-detail', name: 'University', icon: <FaBuildingColumns /> },
                { path: '/institute', name: 'Institute', icon: <FaBuildingColumns /> },
                {
                    path: '/student', name: 'Student', icon: <PiStudentFill />
                },
                {
                    path: '/upload-document', name: 'Upload Data', icon: <TbDatabaseImport/>
                },
                { path: '/upload-details', name: 'Upload Details', icon: <TbListDetails /> },
                { path: '/upload-eCard', name: 'Upload E-Card', icon: <TbListDetails /> },
                { path: '/profile', name: 'Profile', icon: <FaUserAlt /> }

            ];
            setMenuItem(menuItem)
        }
        if (Role === "4") {   // for university
            const menuItem = [
                { path: '/', name: 'Dashboard', icon: <RiAdminFill /> },

                { path: '/university-detail', name: 'University', icon: <FaBuildingColumns /> },
                

                { path: '/institute', name: 'Institute', icon: <FaBuildingColumns /> },
                {
                    path: '/student', name: 'Student', icon: <PiStudentFill />
                },
                
                { path: '/enrollment', name: 'Enrollment', icon: <FaBuildingColumns /> },
                { path: '/profile', name: 'Profile', icon: <FaUserAlt /> }

            ];
            setMenuItem(menuItem)
        } 
        if (Role === "5") {    // for institute
            const menuItem = [
                { path: '/', name: 'Dashboard', icon: <RiAdminFill /> }, 
                // { path: '/university-detail', name: 'University', icon: <FaBuildingColumns /> },   // no need to show university for colloges
                { path: '/institute', name: 'Institute', icon: <FaBuildingColumns /> },
                {
                    path: '/student', name: 'Student', icon: <PiStudentFill />
                },
                { path: '/enrollment', name: 'Enrollment', icon: <FaBuildingColumns /> },
                { path: '/profile', name: 'Profile', icon: <FaUserAlt /> }

            ];
            setMenuItem(menuItem)
        }
        if (Role === "6") {   // for students 
            const menuItem = [
                // { path: '/', name: 'Admin', icon: <RiAdminFill /> },

                // { path: '/institute', name: 'Institute', icon: <FaBuildingColumns /> },
                {
                    path: `/student-details/${id}`, name: 'Student', icon: <PiStudentFill />
                },
                // { path: '/enrollment', name: 'Enrollment', icon: <FaBuildingColumns /> },
                { path: '/profile', name: 'Profile', icon: <FaUserAlt /> }

            ];
            setMenuItem(menuItem)
        }

    }, [])




    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="container-flex">
            <div className="d-sm-block">
                <div style={{ width: isOpen ? '200px' : '50px' }} className="sidebar">
                    <div className="top_section">
                        {/* <h2 style={{ display: isOpen ? 'block' : 'none' }} className="logo">
                            Panel
                        </h2> */}
                        <img src='/LOGO-01.svg'style={{ display: isOpen ? 'block' : 'none' }} className='navbarImageSize' />
                        <div style={{ marginLeft: isOpen ? '50px' : '0px' }} className="bars">
                            <FaBars onClick={toggle}  />
                        </div>
                    </div>
                    {menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" activeClassName="active">
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? 'block' : 'none' }} className="link_text">
                                {item.name}
                            </div>
                        </NavLink>
                    ))}
                </div>
            </div>

            <div className="content">
                <TopHeader onToggleSidebar={toggle} onToggleOffCanvas={toggleOffCanvas} />
                <main className="main-content">{children}</main>
            </div>

            <div className="d-lg-none d-md-none">
                <Offcanvas
                    show={showOffCanvas}
                    onHide={() => setShowOffCanvas(false)}
                    className="offcanvas offcanvas-background-color"

                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><img src='/LOGO-01.svg' className='navbarImageSizeOne' alt='' /></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="sidebar-mobile">

                            {menuItem.map((item, index) => (
                                <NavLink
                                    to={item.path}
                                    key={index}
                                    className="link"
                                    activeClassName="active"
                                    onClick={() => setShowOffCanvas(false)}
                                >
                                    <div className="icon">{item.icon}</div>
                                    <div className="link_text">
                                        {item.name}
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    );
};

export default Sidebar;
