import { MDBDataTable } from "mdbreact";
import apiUrls from "../../Api";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CSVLink } from 'react-csv';

const InsuranceStud = () => {
    const Name = sessionStorage.getItem('name');
    const universityData = `${apiUrls.InsuranceUni}?Inscomp=${Name}&Stud`;
    const [uniData, setUniData] = useState([]);

    useEffect(() => {
        axios.get(universityData)
            .then((response) => {
                setUniData(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error('Error fetching university data:', error);
            });
    }, []);
    const formatDataForCSV = () => {
        const csvData = uniData.map((uni) => ({
            University: uni.universityname || '',
            Institute: uni.institute_name || '',
            StudentName: uni.StudentName || '',
            EmailID: uni.EmailID || '',
            CompanyName: uni.company_name || '',
            PlanName: uni.plan_name || '',
        }));
        return csvData;
    };


    return (
        <div>
            <div className="mb-2">
                <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
                    <button className="btn btn-warning">Download</button>
                </CSVLink>
            </div>
            <MDBDataTable
                striped
                bordered
                hover
                data={{
                    columns: [
                        {
                            label: 'University',
                            field: 'universityname',
                            sort: 'asc',
                        },
                        {
                            label: 'Institute',
                            field: 'institute_name',
                            sort: 'asc',
                        },
                        {
                            label: 'Student',
                            field: 'StudentName',
                            sort: 'asc',
                        },
                        {
                            label: 'EmailID',
                            field: 'EmailID',
                            sort: 'asc',
                        },
                        {
                            label: 'Company',
                            field: 'company_name',
                            sort: 'asc',
                        },
                        {
                            label: 'Plan',
                            field: 'plan_name',
                            sort: 'asc',
                        },
                        {
                            label: 'View',
                            field: 'view',
                            sort: 'asc',
                        }

                    ],
                    rows: uniData.map((uni) => ({
                        universityname: uni.universityname,
                        institute_name: uni.institute_name,
                        StudentName: uni.StudentName,
                        EmailID: uni.EmailID,
                        company_name: uni.company_name,
                        plan_name: uni.plan_name,
                        // type: uni.type,         
                        // total_students: uni.type === 'Named' ? uni.no_of_stud : uni.total_stud,
                        // total_premium: uni.total_premium,
                        view: (
                            <Link
                                to={`/student-details/${uni.id}`} // Assuming uni.id is the university ID
                                className="btn btn-warning"
                            >
                                View
                            </Link>
                        )
                    })),
                }}
                responsive
                noBottomColumns
            />
        </div>
    );
};

export default InsuranceStud;
