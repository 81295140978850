import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import apiUrls from '../../Api';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

const AllStudents = () => {
    const Role = sessionStorage.getItem('role');
    const UniId = sessionStorage.getItem('uni_id');
    const InstId = sessionStorage.getItem('inst_id')
    const StudId = sessionStorage.getItem("stud_id")
    const [universityData, setUniversityData] = useState('');
    const [uniData, setUniData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        if (Role === "4") {
            setUniversityData(`${apiUrls.AllStudents}?uni_id=${UniId}`);
        } else if (Role === "1" || Role === "2") {
            setUniversityData(apiUrls.AllStudents);
        } else if (Role === "5") {
            setUniversityData(`${apiUrls.AllStudents}?InstituteID=${InstId}`)
        }
        //else if(Role  === "6"){
        //     setUniversityData(`${apiUrls.AllStudents}?id=${StudId}`)
        // }
    }, [Role, UniId, InstId]); // Add Role and UniId as dependencies

    useEffect(() => {
        if (universityData) { // Check if universityData is available
            axios.get(universityData)
                .then((response) => {
                    console.log(response.data);
                    setUniData(response.data);
                    setLoading(false); // Set loading to false after data is fetched
                })
                .catch((error) => {
                    console.error('Error fetching university data:', error);
                });
        }
    }, [universityData]); // Add universityData as dependency

    const formatDataForCSV = () => {
        const csvData = uniData.map((uni) => ({
            StudentName: uni.StudentName || '',
            District: uni.city || '',
            Email: uni.EmailID || '',
            Mobile: uni.ContactNumber || '',
            Gender: uni.Gender || '',
            DOB: uni.DOB || '',
            GuardianName: uni.GuardianName || ''
        }));
        return csvData;
    };


    return (
        <div className="table-container" style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <div className="mb-2">
                <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
                    <button className="btn btn-warning">Download</button>
                </CSVLink>
            </div>
            {loading ? ( // Render loading indicator if data is still loading
                <p>Loading...</p>
            ) : (
                <MDBDataTable
                    striped
                    bordered
                    hover
                    data={{
                        columns: [
                            {
                                label: 'Name',
                                field: 'StudentName',
                                sort: 'asc',
                            },
                            {
                                label: 'Taluka',
                                field: 'city',
                                sort: 'asc',
                            },
                            {
                                label: 'Email',
                                field: 'EmailID',
                                sort: 'asc',
                            },
                            {
                                label: 'Mobile',
                                field: 'ContactNumber',
                                sort: 'asc',
                            },
                            {
                                label: 'Gender',
                                field: 'Gender',
                                sort: 'asc',
                            },
                            {
                                label: 'DOB',
                                field: 'DOB',
                                sort: 'asc',
                            },
                            {
                                label: 'Guardian',
                                field: 'GuardianName',
                                sort: 'asc',
                            },
                            {
                                label: 'View',
                                field: 'view',
                                sort: 'asc',
                            }
                        ],
                        rows: uniData.map((uni) => ({
                            StudentName: uni.StudentName,
                            city: uni.city,
                            EmailID: uni.EmailID,
                            ContactNumber: uni.ContactNumber,
                            Gender: uni.Gender,
                            DOB: uni.DOB,
                            GuardianName: uni.GuardianName,
                            view: (
                                <Link
                                    to={`/student-details/${uni.id}`} // Assuming uni.id is the university ID
                                    className="btn btn-warning"
                                >
                                    View
                                </Link>
                            )
                        }))
                    }}
                    responsive
                    noBottomColumns
                />
            )}
        </div>
    );
};

export default AllStudents;
