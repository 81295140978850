import axios from 'axios'
import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link, Params, useParams } from 'react-router-dom'
import apiUrls from '../../Api'
import { CSVLink } from 'react-csv';

const StudWithInst = ({ InstWiseStud }) => {

    const SpecificStudents = `${apiUrls.AllStudents}?InstituteID=${InstWiseStud}`
    const [instData, setInstData] = useState([])
    useEffect(() => {
        axios.get(SpecificStudents)
            .then((response) => {
                setInstData(response.data)
            })
    }, [])

    const formatDataForCSV = () => {
        const csvData = instData.map((uni) => ({
            StudentName: uni.StudentName || '',
            District: uni.city || '',
            Email: uni.EmailID || '',
            Mobile: uni.ContactNumber || '',
            Gender: uni.Gender || '',
            DOB: uni.DOB || '',
            GuardianName: uni.GuardianName || ''
        }));
        return csvData;
    };


    return (
        // <div className='container'>
        <div>
            <div className="mb-2">
                <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
                    <button className="btn btn-warning">Download</button>
                </CSVLink>
            </div>
            <MDBDataTable

                striped
                bordered
                responsive
                noBottomColumns
                hover
                data={{
                    columns: [
                        {
                            label: 'Name',
                            field: 'StudentName',
                            sort: 'asc',
                        },
                        {
                            label: 'City',
                            field: 'city',
                            sort: 'asc',
                        },
                        {
                            label: 'Email',
                            field: 'EmailID',
                            sort: 'asc',
                        },
                        {
                            label: 'Mobile',
                            field: 'ContactNumber',
                            sort: 'asc',
                        },
                        {
                            label: 'Gender',
                            field: 'Gender',
                            sort: 'asc',
                        },
                        {
                            label: 'DOB',
                            field: 'DOB',
                            sort: 'asc',
                        },
                        {
                            label: 'GuardianName',
                            field: 'GuardianName',
                            sort: 'asc',
                        },
                        {
                            label: 'View',
                            field: 'view',
                            sort: 'asc',
                        }
                    ],
                    rows: instData.map((uni) => ({
                        StudentName: uni.StudentName,
                        city: uni.city,
                        EmailID: uni.EmailID,
                        ContactNumber: uni.ContactNumber,
                        Gender: uni.Gender,
                        DOB: uni.DOB,
                        GuardianName: uni.GuardianName,
                        view: (
                            <Link
                                to={`/student-details/${uni.id}`} // Assuming uni.id is the university ID
                                className="btn btn-warning"
                            >
                                View
                            </Link>
                        )
                    }))
                }}


            />
        </div>
    );
}

export default StudWithInst