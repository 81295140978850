import React, { useEffect, useState } from 'react'
import StatsCards from '../../Components/StatsCards'
import Chart from '../../Components/Chart'
import TableDashboard from '../../Components/TableDashboard'
import { columns , data } from "../../Data/DashboardMockData"
import Test  from "../InstituteDetail/TestTable"
import { useNavigate } from 'react-router-dom'

function DashBoard() {

  return (
    <>
   
      <StatsCards />
      <Chart/>
      <TableDashboard />
      {/* <Test/> */}
    </>
  )
}

export default DashBoard