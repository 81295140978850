import React, { useState } from 'react'
import { UserData } from '../Data/StaticDataGraph';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { Card } from 'react-bootstrap';

const DoughnutChart = () => {
    const [userData, setUserData] = useState({
        labels: UserData.map((data) => data.year),
        datasets: [
            {
                label: "Users Gained",
                data: UserData.map((data) => data.userGain),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0",
                ],
                borderColor: "white",
                borderWidth: 3,
                hoverOffset: 30

            },
        ],
    });
    const options = {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: -5,
                bottom: 10,
            }
        }
    }
    return (
        <>
            <Card >
                <Card.Body>
                    <Card.Title>Claim Data</Card.Title>
                    <div className='circle-graph' style={{ width:"300px" , height:"300px" }}>

                        <Doughnut data={userData} options={options} />
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default DoughnutChart