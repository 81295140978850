
// const BASE_URL = 'https://clrapi.loginapps.in/Api';
//  const BASE_URL = 'http://localhost/Yojana/swami_yojna/api/local'
// const BASE_URL = 'https://svyuvasurakshaapi.loginapps.in/Api/local'
// const BASE_URL = 'http://20.197.41.49'
const BASE_URL = 'https://admin.svyuvasuraksha.org'

// const BASE_URL = 'https://buypolicynow.com/SVSYapi/api/local'
// const BASE_URL_ICICI = "https://iribl.com/SVYSY/api/Payment"
const BASE_URL_ICICI = "https://svyuvasuraksha.org/ICPayment/api/Payment"



// https://iribl.com/SVYSY/api/test/createbasetransaction 
const apiUrls = {
    LoginUrl: `${BASE_URL}/loginWithPassApi.php`,
    InstituteTempUrl: `${BASE_URL}/instStudTempSubmit.php`,
    InstituteFinalUrl: `${BASE_URL}/instStudSubmit.php`,
    UniversityTempUrl: `${BASE_URL}/uniStudTempSubmit.php`,
    UniversityFinalUrl: `${BASE_URL}/uniStudSubmit.php`,
    TotalMember: `${BASE_URL}/totalMemberApi.php`,
    ChartGraph: `${BASE_URL}/ChartGraphApi.php`,
    PieGraph: `${BASE_URL}/pieChart.php`,
    UniversityData: `${BASE_URL}/displayAllUniApi.php`,
    InstituteWithUniversity: `${BASE_URL}/displayInstByUniApi.php`,
    AllInstitute: `${BASE_URL}/displayAllInstituteApi.php`,
    AllStudents: `${BASE_URL}/displayAllStud.php`,
    InsuranceUni: `${BASE_URL}/displayPolicyholders.php`,
    MultipleBarChart: `${BASE_URL}/loginChartGraphApi.php`,
    ForgotPassword: `${BASE_URL}/forgotPassSendOtpApi.php`,
    Verification: `${BASE_URL}/resetPasswordapi.php`,
    StudentLogin: `${BASE_URL}/studenLoginApi.php`,
    TotalCountInsurance: `${BASE_URL}/displayPolicyholdersCount.php`,
    RecentData: `${BASE_URL}/recentUploadApi.php`,
    Receipt: `${BASE_URL}/receiptPayment.php`,
    UploadsDetails: `${BASE_URL}/receiptView.php`,
    paymentStatus: `${BASE_URL}/paymentLog.php`,
    authTokenGenerate: `${BASE_URL}/tokenGenret.php`,
    createbasetransaction: `${BASE_URL}/tran.php`, // to get the transcation id
    paymentVerification: `${BASE_URL}/verifyTransaction.php`,// to verify the transcation
    signUp : `${BASE_URL}/signup.php`,
    iciciRedirect : "https://ilesb01.insurancearticlez.com"
};
// export const Base_File_Path = `http://localhost/Yojana/swami_yojna/uploads1/studentExcel/excelerror/`
export const Base_File_Path = `https://svyuvasurakshaapi.loginapps.in/uploads1/studentExcel/excelError/`

export default apiUrls;

/// hello //
