import React from 'react'
import InsuranceCompany from './InsuranceCompany'

function Insurance() {
  return (
    <>
    <InsuranceCompany/>
    </>
  )
}

export default Insurance