import React from 'react'
import UniSignUp from './UniSignUp'
import TopHeader from '../../Components/Constants/TopHeader'
import TopHeaderOne from '../../Components/Constants/TopHeaderOne'
import FooterOne from '../../Components/Constants/FooterOne'

function Register() {
    return (
        <>
            <TopHeaderOne />
            <UniSignUp />
            <FooterOne />
        </>
    )
}

export default Register