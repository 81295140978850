import React from 'react'
import TopHeaderOne from '../../Components/Constants/TopHeaderOne'
import FooterOne from '../../Components/Constants/FooterOne'
import Payment from './Payment'

const PaymentStatus = () => {
    return (
        <>
            {/* <TopHeaderOne /> */}
            <Payment />
            {/* <FooterOne /> */}
        </>
    )
}

export default PaymentStatus