import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import apiUrls from '../../Api';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

const UniTestTable = () => {
    const universityData = apiUrls.UniversityData;
    const [uniData, setUniData] = useState([]);

    useEffect(() => {
        axios.get(universityData)
            .then((response) => {
                setUniData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching university data:', error);
            });
    }, []);

    // Function to format data for CSV export
    const formatDataForCSV = () => {
        const csvData = uniData.map((uni) => ({
            University: uni.universityname || '',
            District: uni.city || '',
            Taluka: uni.taluka || '',
            Email: uni.email || '',
            Mobile: uni.phone_number || '',
            Principal: uni.Principal || '',
            'College Type': uni.uni_type || '',
        }));
        return csvData;
    };

    return (
        <div>
            <div className="mb-2">
                <CSVLink data={formatDataForCSV()} filename={"university_data.csv"}>
                    <button className="btn btn-warning">Download</button>
                </CSVLink>
            </div>
            <MDBDataTable
                striped
                bordered
                hover
                data={{
                    columns: [
                        {
                            label: 'University',
                            field: 'universityname',
                            sort: 'asc',
                        },
                        {
                            label: 'District',
                            field: 'city',
                            sort: 'asc',
                        },
                        {
                            label: 'Taluka',
                            field: 'taluka',
                            sort: 'asc',
                        },
                        {
                            label: 'Email',
                            field: 'email',
                            sort: 'asc',
                        },
                        {
                            label: 'Mobile',
                            field: 'phone_number',
                            sort: 'asc',
                        },
                        {
                            label: 'Principal',
                            field: 'Principal',
                            sort: 'asc',
                        },
                        {
                            label: 'College Type',
                            field: 'uni_type',
                            sort: 'asc',
                        },
                        {
                            label: 'View',
                            field: 'view',
                            sort: 'asc',
                        }
                    ],
                    rows: uniData.map((uni) => ({
                        universityname: uni.universityname,
                        city: uni.city,
                        taluka: uni.taluka,
                        email: uni.email,
                        phone_number: uni.phone_number,
                        Principal: uni.Principal,
                        uni_type: uni.uni_type,
                        view: (
                            <Link
                                to={`/university/institute-details/${uni.id}`} // Assuming uni.id is the university ID
                                className="btn btn-warning"
                            >
                                View
                            </Link>
                        )
                    }))
                }}
                responsive
                noBottomColumns
                className="fixed-header" // Add a custom class for styling
            />

        </div>
    );
};

export default UniTestTable;
