import React from 'react'
import UpdatePassword from './UpdatePassword'
import TopHeaderOne from '../../Components/Constants/TopHeaderOne'
import FooterOne from '../../Components/Constants/FooterOne'

function ForgotPassword() {
  return (
    <>
    <TopHeaderOne/>
    <UpdatePassword/>
    <FooterOne/>
    </>
  )
}

export default ForgotPassword